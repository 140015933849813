import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    duration: Number
  }

  connect() {
    this.fadeIn();
    if (this.durationValue && this.durationValue > 0) {
      setTimeout(() => {
        this.fadeOut();
      }, this.durationValue);
    } else {
      setTimeout(() => {
        this.fadeOut();
      }, 3000);
    }
  }

  fadeIn() {
    this.element.classList.add('fade-in');
  }

  fadeOut() {
    this.element.classList.remove('fade-in');
    this.element.classList.add('fade-out');
  }

}
