<template>
  <div class="form-editor-input-container">
    <base-typography v-if="label" formLabel capitalize>{{ label }}</base-typography>
    <base-typography v-if="errorMessage" warning>{{errorMessage}}</base-typography>
    <div class="input-row-container">
      <i v-if="showPencil" class="fa fa-pencil" />
      <base-input
        class="form-template-editor-input" 
        v-model="value"
      />
    </div>
  </div>
  
</template>

<script setup lang="ts">
import BaseInput from '@/ui-library/base-input/base-input.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { useModelWrapper } from '@/composition/use-model-wrapper';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  errorMessage: {
    type: String,
    required: false
  },
  showPencil: {
    type: Boolean,
    default: true
  },
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    required: false,
    default: ''
  }
});

const value = useModelWrapper(props, emit)

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.form-editor-input-container {
  .input-row-container {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    .form-template-editor-input {
      width: inherit;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $ava-border-color;
    }
  }
}
</style>