<template>
  <div class="base-input-container">
    <base-typography v-if="warningLabel" warning>{{warningLabel}}</base-typography>
    <input
      v-bind="$attrs"
      class="base-input" 
      :value="modelValue"
      @input="$emit('update:modelValue', inputEventHandler($event))"
    />
  </div>
</template>

<script lang="ts">
/**
 * https://vuejs.org/guide/components/events.html#usage-with-v-model
 */
import { defineComponent } from 'vue';
import BaseTypography from '../base-typography/base-typography.vue';

export default defineComponent({
  inheritAttrs: false,
  components: { BaseTypography },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    },
    warningLabel: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup() {
    const inputEventHandler = (event: Event): string => {
      return (event.target as HTMLInputElement).value
    }
    return {
      inputEventHandler
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.base-input-container {
  width: inherit;
  .base-input {
    text-align: inherit;
    padding: 4px;
    border: 1px solid $ava-border-color;
    height: $ava-form-item-height;
    line-height: $ava-form-item-line-height;
    padding: $ava-form-item-padding;
    margin: 0px;
    width: 100%;
  }
}
</style>