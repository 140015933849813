import { defineStore } from 'pinia';
import { withLoader } from '../../../utils/store-helpers';
import { addClinicFormTemplate, deleteClinicFormTemplate, fetchMyClinicFormTemplates } from '../services/my-clinic-form-templates-service';
import { MyClinicFormTemplatesState } from '../types/my-clinic-form-templates-state';

export const useMyClinicFormTemplatesStore = defineStore('useMyClinicFormTemplatesStore', {
  state: (): MyClinicFormTemplatesState => ({
    isLoading: false,
    clinicId: undefined,
    clinicName: undefined,
    clinicFormIds: []
  }),
  getters: {
    getIsInitialStateFetched: (state) => state.clinicId !== undefined,
    getClinicName: (state) => state.clinicName 
  },
  actions: {
    async initMyClinicFormTemplates() {
      withLoader.call(this, async () => {
        this.updateStateFromDto(await fetchMyClinicFormTemplates());
      })
    },
    async updateClinicFormTemplate(status: boolean, templateId: number | null) {
      if (!templateId) {
        throw new Error("Template is not known");
      }
      withLoader.call(this, async () => {
        const dto = status 
          ? await addClinicFormTemplate(templateId) 
          : await deleteClinicFormTemplate(templateId)
        this.updateStateFromDto(dto);
      })
    },
    updateStateFromDto(dto: Partial<MyClinicFormTemplatesState>) {
      this.clinicId = dto.clinicId;
      this.clinicName = dto.clinicName;
      this.clinicFormIds = dto.clinicFormIds || [];
    },
    isFormAddedToMyClinic(id: number | null): boolean {
      if (!id) return false
      return this.clinicFormIds.includes(id)
    }
  }
});