<template>
  <browse-page-layout>
    <template #create-form>
      <create-patient-form-template
        class="create-patient-forms-container" 
        v-model="newTemplateName" 
        @submit="submitNewForm" 
      />
    </template>
    <template #list-forms>
      <patient-forms-template-list
        :list="getPatientFormList" 
        @goToEditTemplate="goToEditTemplate" 
      />
      <base-pagination-buttons 
        v-if="store.getPages > 1"
        :page="store.getPage" 
        :pages="store.getPages" 
        @next="store.fetchMyAuthoredFormTemplates"
        @prev="store.fetchMyAuthoredFormTemplates"
      />
    </template>
  </browse-page-layout>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePatientFormTemplateStore } from '../../store/custom-patient-forms-store';
import BrowsePageLayout from './layouts/browse-page-layout.vue';
import CreatePatientFormTemplate from '../../components/create-patient-form-template.vue';
import PatientFormsTemplateList from '../../components/patient-forms-template-list.vue';
import { useRouter } from 'vue-router';
import BasePaginationButtons from '@/ui-library/base-pagination-buttons/base-pagination-buttons.vue';

const store = usePatientFormTemplateStore();
const { 
  getPatientFormList,
  newTemplateName, 
} = storeToRefs(store);

const submitNewForm = () => store.createFormTemplate();

const router = useRouter();

const goToEditTemplate = (id: number) => router.push({ name: 'patient-form-template', params: { id } });

onMounted(() => {
  store.fetchMyAuthoredFormTemplates(1)
})

</script>

<style lang="scss" scoped>
.base-pagination {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
}
</style>