<template>
  <div class="patient-forms-template-list">
    <table v-if="list?.length">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr 
          class="patient-form-selection ava-add-border-on-hover" 
          v-for="item in list" 
          :key="item.id" 
          @click="goToEditTemplate(item.id)">
          <td>{{ item.name }}</td>
          <td>{{ formatDateFromString(item.createdAt) }}</td>
          <td>
            <i v-if="item.published" class="fa fa-book published-icon" title="Published: All Ava users can use my form"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <base-typography v-else>
      You have not created any form templates yet.
    </base-typography>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { PatientFormTemplateListItem } from '../types/patient-form-template-list-item';
import { formatDateFromString } from '@/utils/date-helpers';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';

defineProps({
  list: {
    type: Array as PropType<PatientFormTemplateListItem[] | null>,
    required: false,
    default: null
  }
})

const emit = defineEmits({
  goToEditTemplate: (templateId: number) => Number.isFinite(templateId)
})

const goToEditTemplate = (id: number) => {
  emit('goToEditTemplate', id);
}

</script>

<style lang="scss" scoped>
  @import '@/styles/global-styles.scss';
.patient-forms-template-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .edit-template-button {
    width: 100%;
    height: 2em;
    border: 1px solid $ava-border-color;
    &:hover {
      border: 1px solid $ava-affirmative-color;
    }
  }
  .patient-form-selection {
    cursor: pointer;
    .published-icon {
      color: #51AF98
    }
    .not-published-icon {
      color: #cc5500
    }    
  }
}
</style>