import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    const promptElements = document.getElementsByClassName('consult-request-text-generator-prompt');
    const prompt = (promptElements.length > 0) ? promptElements[0].value : null;
    const patientSgid = this.data.get("patient-sgid");
    const clinicSgid = this.data.get("clinic-sgid");
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    document.getElementById('initial-prompt').value = prompt;
    $.ajax({
      url: `/ava_assists`,
      method: 'POST',
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
      data: {
        prompt: prompt,
        reason_for_referral: document.getElementById('assist-reason-for-referral').value,
        chart_data: document.getElementById('assist-chart-data').value,
        clinical_note_id: document.getElementById('assist-clinical-note-id').value,
        patient_sgid: patientSgid,
        clincic_sgid: clinicSgid
      },
      dataType: 'script'
    });
  }
  cancel() {
    document.getElementById('new-file-form-component').remove();
  }
}
