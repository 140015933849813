import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    patientId: Number,
    patientName: String,
    patientSex: String,
    patientIdentifier: String,
    patientCity: String,
    patientProvince: String,
  }

  clickEvent() {
    // Dispatch a custom event with patient details up to any listening parent controller
    const detail = {
      patientId: this.patientIdValue,
      patientName: this.patientNameValue,
      patientSex: this.patientSexValue,
      patientIdentifier: this.patientIdentifierValue,
      patientCity: this.patientCityValue,
      patientProvince: this.patientProvinceValue,
    }
    this.element.dispatchEvent(new CustomEvent("patient:selected", { detail, bubbles: true }))
  }
}
