import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];
  connect() {
    this.form = this.containerTarget.closest('form');
    this.boundHandleFormSubmit = this.handleFormSubmit.bind(this);
    this.form?.addEventListener('submit', this.boundHandleFormSubmit);
  }

  disconnect() {
    this.form?.removeEventListener('submit', this.boundHandleFormSubmit);
  }

  handleFormSubmit(event) {
    this.containerTarget.querySelector('input')?.blur();
  }

  submitForm(event) {
    event.preventDefault();
    this.form.requestSubmit();
  }
  
}
