import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        modal: { type: String, default: 'info-modal' },
        offset: { type: Number, default: 0 }
    }

    connect() {
        this.modal = document.getElementById(this.modalValue);
        this.overlay = this.modal.parentElement;

        this.showModal()
        document.addEventListener('click', this.hideModalOnClick.bind(this));
    }

    disconnect() {
        document.removeEventListener('click', this.hideModalOnClick.bind(this));
    }

    showModal() {
        this.modal.style.display = 'block';
        this.modal.style.top = this.offsetValue + 'px';
        this.overlay.style.display = 'block';
    }

    hideModalOnClick(event) {
        if (this.modal && !this.modal.contains(event.target)) {
            this.modal.style.display = 'none';
            this.overlay.style.display = 'none';
        }
    }
}