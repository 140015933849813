<template>
  <base-transition-expand>
    <div data-testid="loading" v-if="isLoading" class="base-loader" :class="classes"> 
      <div v-if="message" class="base-loader-message">{{ message }}</div>
      <template v-else>
        <div class="base-loader-dot" />
        <div class="base-loader-dot" />
        <div class="base-loader-dot" />
      </template>
    </div> 
  </base-transition-expand>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import BaseTransitionExpand from '../base-transition/base-transition-expand.vue';

export default defineComponent({
  components: { BaseTransitionExpand },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    fullScreen: {
      type: Boolean,
      required: false
    },
    message: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { fullScreen } = toRefs(props);
    const classes = computed(() => ({
      'base-loader-fullscreen': fullScreen.value
    }))
    return {
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
.base-loader {
  gap: 4px;
  margin: 8px 0px;
  display: flex;
  transform: scale(1);
  align-items: baseline;
  .base-loader-message {
    padding-right: 8px;
    color: grey;
    animation: pulse-message .7s infinite alternate;
  }
  .base-loader-dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    animation: pulse .7s infinite alternate;
    @for $i from 1 through 3 {
      &:nth-child(#{$i+1}) {
        animation-delay: #{$i*.1}s;
      }
    }
  }
}
.base-loader-fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: gray;
  height: 100vh !important;
  width: 100vw;
  margin: 0;
  z-index: 1;
  opacity: 0.4;
  .base-loader-dot {
    background-color: white;
  }
}

@keyframes pulse {
  to { transform: scale(0.7) }
}
@keyframes pulse-message {
  to { transform: scale(0.95) }
}
</style>