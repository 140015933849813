import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["foldableCardContent", "dropDownButton"]

  toggleFoldableCard() {
    if (this.foldableCardContentTarget.classList.contains("hidden-field")) {
      this.foldableCardContentTarget.classList.remove("hidden-field")
      this.dropDownButtonTarget.classList.add("foldable-card-rotate-180")
    }
    else {
      this.foldableCardContentTarget.classList.add("hidden-field")
      this.dropDownButtonTarget.classList.remove("foldable-card-rotate-180")
    }

  }
}