import {
  Mark,
  mergeAttributes,
} from '@tiptap/core'

export const DynamicMacroMark = Mark.create({
  name: 'dynamicMacroMark',

  inclusive: false,

  exitable: true,

  spanning: false,

  addOptions() {
    return {
      HTMLAttributes: {
        'data-dynamic-macro': '',
        style: 'outline: 1px dotted; border-radius: 4px; padding: 0 8px;'
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: element => element.hasAttribute('data-dynamic-macro') && null,
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  }
})

