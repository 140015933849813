<template>
  <textarea
    v-bind="$attrs"
    ref="avaTextarea"
    @keyup="textAreaAdjust"
    :value="modelValue"
    @input="
      $emit('update:modelValue', ($event.target as HTMLTextAreaElement).value)
    "
    :placeholder="placeholder"
    class="ava-textbox-grow ava-form-item-with-text-input"
    :class="disabledClass"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const disabledClass = computed(() =>
      props.disabled ? "disabled-class" : ""
    );

    const avaTextarea = ref<HTMLTextAreaElement | undefined>();

    function textAreaAdjust() {
      if (avaTextarea.value) {
        avaTextarea.value.style.height = "1px";
        avaTextarea.value.style.height =
          10 + avaTextarea.value.scrollHeight + "px";
      }
    }
    onMounted(() => {
      textAreaAdjust();
    });

    return {
      avaTextarea,
      disabledClass,
      textAreaAdjust,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/global-styles.scss';

.ava-textbox-grow {
  border: 1px solid $ava-border-color;
  padding: $ava-form-item-padding;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  &:focus {
    box-shadow: 0px 0px 3px rgb(80, 143, 155);
  }
}

.ava-textbox-grow.disabled-class {
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  background: rgba(242, 244, 245, 255);
}
</style>
