import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {

  static targets = ["spinner", "buttonText", "button"];

  connect() {
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    document.addEventListener('turbo:before-fetch-request', this.startLoading);
    document.addEventListener('turbo:before-fetch-response', this.stopLoading);
  }

  startLoading() {
    this.spinnerTarget.classList.remove("hidden");
    this.buttonTarget.classList.add("hidden");
  }

  stopLoading() {
    this.spinnerTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("hidden");
  }
}
