import { Application } from "@hotwired/stimulus"

// don't know how Turbo will interact in current codebase so turning it off by default

import "@hotwired/turbo-rails"

Turbo.session.drive = false

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
