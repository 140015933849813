import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    this.decipherIndexMutationObserver();
    this.decipherFormMutationObserver();
  }

  close() {
    this.element.classList.add('closed');
  }

  open() {
    this.element.classList.remove('closed');
  }

  openForm() {
    const formDiv = document.getElementById('decipher-form');
    if (formDiv) {
      formDiv.classList.remove('hidden-field');
    }
  }

  closeForm() {
    const formDiv = document.getElementById('decipher-form');
    if (formDiv) {
      formDiv.classList.add('hidden-field');
    }
  }

  openIndex() {
    const indexDiv = document.getElementById('decipher-index');
    if (indexDiv) {
      indexDiv.classList.remove('hidden-field');
    }
  }

  closeIndex() {
    const indexDiv = document.getElementById('decipher-index');
    if (indexDiv) {
      indexDiv.classList.add('hidden-field');
    }
  }

  decipherIndexMutationObserver() {
    const turboFrame = document.getElementById('decipher-index');
  
    // Create a MutationObserver to monitor changes in the turbo frame
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // Content has changed, trigger the index to be displayed and the form to be hidden
          this.closeForm();
          this.openIndex();
        }
      }
    });
  
    // Configure the observer to watch for child list changes
    const config = { childList: true, subtree: true };
  
    // Start observing the turbo frame for changes
    observer.observe(turboFrame, config);
  }

  decipherFormMutationObserver() {
    const turboFrame = document.getElementById('decipher-form');
  
    // Create a MutationObserver to monitor changes in the turbo frame
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // Content has changed, remove the 'hidden-field' class from the decipher form
          // and add 'hidden-field' to the index
          this.openForm();
          this.closeIndex();
        }
      }
    });
  
    // Configure the observer to watch for child list changes
    const config = { childList: true, subtree: true };
  
    // Start observing the turbo frame for changes
    observer.observe(turboFrame, config);
  }

}
