import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    const clinicId = document.getElementById('new_file_exchange_item')?.dataset?.clinicId;
    $.ajax({
      url: `/clinic_management/clinics/${clinicId}/file_exchange_items`,
      method: 'POST',
      data: new FormData(document.getElementById('new_file_exchange_item')),
      processData: false,
      contentType: false,
      dataType: 'script'
    });
  }
  cancel() {
    document.getElementById('new-file-form-component').remove();
  }
}
