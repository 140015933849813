<template>
  <div class="browse-page-layout">
    <slot name="create-form" />
    <slot name="list-forms" />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>

.browse-page-layout {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
</style>