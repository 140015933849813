import { Controller } from "@hotwired/stimulus";
import { env } from "process";

export default class extends Controller {

  connect() {
    const env = this.data.get("env")
    if (env === "testing" || env === "staging") {
      this.element.innerText = env
    } else {
      this.element.innerText = ""
    }
  }

}