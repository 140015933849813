import { randomHex } from '@/utils/text-helpers';
import { PatientFormObjectCategories } from './patient-form-object-categories';
import { FormTemplateFormItem, FormItemSelect, FormItemDate, FormItemNumber, FormItemText, FormItemGroup, PatientFormTemplateDetails, ConditionalFormDetail, ConditionalFormDetailRule } from './patient-form-template-details';
import cloneDeep from 'lodash/cloneDeep';

function replaceFormItemList(id: string, newState: {[key: string]: any}, list: FormTemplateFormItem[]): FormTemplateFormItem[]  {
  return list.map(item => {
    if (item.id === id) {
      return { ...item, ...newState };
    } else if(item.category === 'group') {
      return { ...item, ...{ form_objects: replaceFormItemList(id, newState, item.form_objects || []) } }      
    } else {
      return item
    }
  });
}

export function replaceSection(id: string, newState: {[key: string]: any}, template: PatientFormTemplateDetails): PatientFormTemplateDetails {
  return {
    ...template,
    form: replaceFormItemList(id, newState, [template.form])[0] as FormItemGroup
  }
}

function cloneFormItemByIdFromList(
  id: string, 
  list: FormTemplateFormItem[]
): FormTemplateFormItem | null {
  let item: FormTemplateFormItem | null = null;
  list.some(element => {
    if (element.id === id) {
      item = cloneDeep(element);
      return true
    } else if (element.category === 'group') {
      item = cloneFormItemByIdFromList(id, element.form_objects || [])
      return Boolean(item);
    } else {
      return false
    }
  });
  return item
}
export function cloneFormItemById(id: string, template: PatientFormTemplateDetails) {
  return cloneFormItemByIdFromList(id, [template.form]);
}

function deleteFormItemFromList(id: string, formItems: FormTemplateFormItem[]): FormTemplateFormItem[] {
  return formItems.filter(i => {
    if (i.id === id) {
      return false
    } else if (i.category === 'group') {
      i.form_objects = deleteFormItemFromList(id, i.form_objects);
      return true
    } else {
      return true
    }
  })
  
}

 
export function deleteFormItemFromTemplate(id: string, template: PatientFormTemplateDetails): PatientFormTemplateDetails {
  return {
    ...template,
    form: {
      ...template.form,
      form_objects: deleteFormItemFromList(id, template.form.form_objects)
    }
  }
}


const createDefaultProtoFormItem = (category: PatientFormObjectCategories) => ({
  id: `${category}-${randomHex()}`,
  category,
  order: 0,
  label: '',
  labelAlias: '',
  help: '',
  required: false,
  display: true
})


export function createDefaultSelectFormItem(options: Partial<FormItemSelect> | null = null): FormItemSelect {
  return {
    ...createDefaultProtoFormItem('select'),
    category: 'select',
    field_options: [],
    multiple: false,
    ...options
  }
}
export function createFormItemOptions(value?: string) {
  return {
    value: value || '',
    description: null
  }
}
export function createDefaultDateFormItem(options: Partial<FormItemDate> | null = null): FormItemDate {
  return {
    ...createDefaultProtoFormItem('date'),
    category: 'date',
    ...options
  }
}
export function createDefaultNumberFormItem(options: Partial<FormItemNumber> | null = null): FormItemNumber {
  return {
    ...createDefaultProtoFormItem('number'),
    category: 'number',
    ...options
  }
}
export function createDefaultTextFormItem(options: Partial<FormItemText> | null = null): FormItemText {
  return {
    ...createDefaultProtoFormItem('text'),
    category: 'text',
    ...options
  }
}
export function createDefaultGroupFormItem(options: Partial<FormItemGroup> | null = null): FormItemGroup {
  return {
    ...createDefaultProtoFormItem('group'),
    category: 'group',
    form_objects: [],
    ...options
  }
}

export function createDefaultConditionalFormDetail(): ConditionalFormDetail {
  return {
    id: `conditional-${randomHex()}`,
    condition: 'AND',
    rules: []
  }
}
export function createDefaultConditionalFormDetailRule(): ConditionalFormDetailRule {
  return {
    field_id: '',
    operator: '=',
    value: ''
  }
}