
// opt is the option selected from context menu. It is an object generated by jquery context menu plugin
export const insertTextAtCursor = ({opt, text}) => {
  const jqueryField = opt.$trigger;
  const el = jqueryField[0];
  const caretPos = el.selectionStart;
  const caretEnd = el.selectionEnd;
  const textAreaTxt = jqueryField.val();
  const txtToAdd = jqueryField.val().length > 0 ? `\n${text}` : text;
  jqueryField.val(textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretEnd));
  jqueryField.focus();
  el.selectionStart = caretPos + txtToAdd.length;
  el.selectionEnd = caretPos + txtToAdd.length;
  autosize.update(jqueryField);
}

export const getTextToSaveAsMacro = (opt) => {
  const jqueryField = opt.$trigger
  const caretPos = jqueryField[0].selectionStart;
  const caretEnd = jqueryField[0].selectionEnd;
  const textAreaTxt = jqueryField.val();
  return textAreaTxt.substring(caretPos, caretEnd);
}
