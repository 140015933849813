import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export const formatDateFromString = (
  isoDate: string, 
  preferredFormat?: string
): string => {
  const date = parseISO(isoDate);
  return isValid(date) 
    ? format(date, preferredFormat ?? 'yyyy-MMM-dd')
    : isoDate
};