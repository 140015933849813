import { Controller } from "@hotwired/stimulus";
import { consumer } from "../../../javascript/channels/consumer";

export default class extends Controller {
  static values = { nonce: String, target: String };

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "EmrChannels::DecipherChannel", nonce: this.nonceValue },
      {
        received: this.handleReceived.bind(this)
      }
    )
    console.log("Created subscription: ", this.subscription);
  }

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription)
    }
  }

  handleReceived(data) {
    aiPredictionId = data.id
    // Either display or
    // Fetch and append
  }
}
