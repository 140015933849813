<template>
  <div class="edit-form-item-layout-container">
    <base-typography v-if="!item"> Form item is not available </base-typography>
    <template v-else>
      <slot :proposedItem="proposedItem" :errorMessageState="errorMessageState" />
    </template>
    <form-editor-cancel-submit-buttons
      v-if="!autoSave"
      @close="$emit('close')" 
      @submit="submit" 
    />
  </div>
</template>

<script setup lang="ts">

import { computed, PropType, ref, toRefs, watch } from 'vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { 
  FormTemplateFormItem, 
  FormTemplateFormItemKeys, 
  FormTemplateErrorMessagesState 
} from '@/features/custom-form-templates/types/patient-form-template-details';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import FormEditorCancelSubmitButtons from '../components/form-editor-cancel-submit-buttons.vue';

const emits = defineEmits(['close', 'save', 'submit']);

const props = defineProps({
  required: {
    default: () => [],
    type: Array as PropType<FormTemplateFormItemKeys[]>
  },
  item: {
    required: true,
    type: Object as PropType<FormTemplateFormItem>
  },
  autoSave: {
    type: Boolean,
    default: true
  }
})

const { item: originalItem } = toRefs(props);

const errorMessageState = ref<FormTemplateErrorMessagesState | {}>({});
const proposedItem = ref<FormTemplateFormItem>(cloneDeep(originalItem.value));
const formCanBeSaved = computed(() => Object.keys(errorMessageState.value).length === 0);

const isEmpty = (value) => value === undefined || value === null || Object.keys(value || []).length === 0

if (props.autoSave) {
  watch(proposedItem, () => save(), { deep: true })
}

const resetErrorMessageState = () => errorMessageState.value = {};
const handleRequiredValidation = () => {
  props.required.forEach((step) => {
    if (isEmpty(proposedItem.value[step])) {
      errorMessageState.value[step] = 'required'
    }
  })
};

const save = debounce(() => {
  resetErrorMessageState();
  handleRequiredValidation();
  if (formCanBeSaved.value) {
    emits('save', proposedItem.value);
  }
}, 200)

const submit = () => {
  emits('submit', proposedItem.value);
}

</script>

<style lang="scss" scoped>
.edit-form-item-layout-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
</style>