// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component_name in the component class.
import StarterKit from "@tiptap/starter-kit";
import { Controller as BaseController } from "@hotwired/stimulus";
import { Editor } from '@tiptap/core'
import debounce from "lodash/debounce";
import { wrapInTagIfNotHTML } from "../../utilities/dom_helpers";
import { replaceKeywordWithMacroText, turnDynamicMacrosIntoNodes } from "./editor_helpers";
import { defaultOptions } from './default_editor_options';
import { EditorButtonService } from "./editor_button_service";
import { DynamicMacroMark } from "./extensions/dynamic_macro_mark_extension";
import DynamicMacroNode from './extensions/dynamic_macro_node_extension';
import { DynamicMacroControlsExtension } from "./extensions/dynamic_macro_controls_extension";
import { ContextMenuMacroExtension } from "./extensions/context_menu_macro_extension";
// Color extension is not to be released yet. refer to ./readme-color.md for more info
// import { Color } from './extensions/custom_color_extension';
// import TextStyle from '@tiptap/extension-text-style';


export class Controller extends BaseController {

  static targets = ["contentEditable", "hiddenField"];

  connect() {
    /**
     * richTextSignature is used to identify if the content was edited by Ava's rich text editor
     * If you want to change it make sure to change it in: 
     *  app/helpers/rich_text_helper.rb
     *  The constant '<!-- ava-generated-rich-text -->' is duplicated in both places because one is in js and the other in ruby
     * NOTE: all existing rich text content will need to be updated with the new prefix
     */
    this.richTextSignature = '<!-- ava-generated-rich-text -->'
    this.buttonService = new EditorButtonService(this.element);
    this.debouncedHelpers = this.createDebouncedHelpers();
    this.editor = this.initTiptap(); // Attaching tiptap editor to this so we can destroy it in Stimulus disconnect method
  }

  createDebouncedHelpers() {
    return {
      hiddenFieldUpdater: debounce(this.hiddenFieldUpdater.bind(this), 300),
      replaceKeywordWithMacroText: debounce(replaceKeywordWithMacroText, 300)
    }
  }

  // NOTE: you will likely want to use the debounced version of this function
  hiddenFieldUpdater(editor) {
    turnDynamicMacrosIntoNodes(editor);
    // sync hidden field with the editor's content
    const hiddenRichTextFeatureIdentifiableComment = this.richTextSignature;
    let editorHtml = editor.getHTML();
    if (!editorHtml.startsWith(hiddenRichTextFeatureIdentifiableComment)) {
      editorHtml = hiddenRichTextFeatureIdentifiableComment + editorHtml;
    }
    this.hiddenFieldTarget.value = editorHtml;
  }

  initTiptap(){
    const editor = new Editor({
      ...defaultOptions,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2],
          }
        }),
        DynamicMacroMark,
        DynamicMacroNode,
        DynamicMacroControlsExtension,
        ContextMenuMacroExtension
      ],
      element: this.contentEditableTarget,
      onUpdate: ({ editor }) => {
        // this is what syncs the hidden field with the editor content
        this.debouncedHelpers.hiddenFieldUpdater(editor)
        this.debouncedHelpers.replaceKeywordWithMacroText(editor);
      },
      content: wrapInTagIfNotHTML(this.hiddenFieldTarget.value), // this is the initial content of the editor. If not HTML, then we make it HTML 
      onSelectionUpdate: ({ editor }) => {
        /**
         * runs everytime the selection on the editor changes. We want to highlight appropriate buttons according to what is selected
         * ie. We should highlight Bold button if the selected text is bold
         */
        this.buttonService.highlightActiveButtons(editor)
      }
    });
    this.debouncedHelpers.hiddenFieldUpdater(editor);

    return editor
  }

  bold() {
    this.buttonService.toggleBold(this.editor)
  }

  italic() {
    this.buttonService.toggleItalic(this.editor)
  }
  h1() {
    this.buttonService.toggleH1(this.editor)
  }
  h2() {
    this.buttonService.toggleH2(this.editor)
  }

  bulletList() {
    this.buttonService.toggleBulletList(this.editor)
  }

  orderedList() {
    this.buttonService.toggleOrderedList(this.editor)
  }

  unsetFontColor() {
    this.buttonService.toggleFontColor(this.editor, null)
  }

  setFontColor(event) {
    const color = event.currentTarget.dataset.color;
    this.buttonService.toggleFontColor(this.editor, color)
  }

  disconnect() {
    this.editor.destroy()
  }
}