import axios, { AxiosInstance } from 'axios';
import helpers from './utils/http.functions';
import { Http } from './types/http-instance';

function getCSRFToken(): string | null {
  return (document.head.querySelector('meta[name="csrf-token"]') as any)?.content ?? null;
}

function setCSRFTokenToHeader(httpService: AxiosInstance) {
  const token = getCSRFToken();
  if (token) {
    httpService.defaults.headers.common['X-CSRF-TOKEN'] = token;
  } else {
    console.error('CSRF token not found');
  }
  return httpService;
}

const http: AxiosInstance = (() => {
  const httpService = axios.create();
  window.addEventListener(
    'DOMContentLoaded',
    () => {
      setCSRFTokenToHeader(httpService);
    },
    { once: true }
  );
  return httpService;
})();

async function callGetXmlPayload(endpoint: string) {
  const response = await http.get(endpoint, { headers: helpers.createHeaderForXMLHttpRequest() });
  helpers.fulfillXmlHttpRequest(response);
  return response;
}
async function callPatchXmlPayload(endpoint: string, data?: any) {
  const response = await http.patch(endpoint, data, { headers: helpers.createHeaderForXMLHttpRequest() });
  helpers.fulfillXmlHttpRequest(response);
  return response;
}
async function callPostXmlPayload(endpoint: string, data?: any) {
  const response = await http.post(endpoint, data, { headers: helpers.createHeaderForXMLHttpRequest() });
  helpers.fulfillXmlHttpRequest(response);
  return response;
}
async function callDeleteXmlPayload(endpoint: string) {
  const response = await http.delete(endpoint, { headers: helpers.createHeaderForXMLHttpRequest() });
  helpers.fulfillXmlHttpRequest(response);
  return response;
}

const result = {
  ...http,
  ...helpers,
  callGetXmlPayload,
  callDeleteXmlPayload,
  callPatchXmlPayload,
  callPostXmlPayload,
} as Http

export default result
