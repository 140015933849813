import { nextTick, ref, Ref, watch } from 'vue';

export function useFlashMessage(
  options: UseFlashMessageOptions = { seconds: 3 }
): FlashMessage {
  const flashText = ref('');
  const flashSeconds = ref(options.seconds);
  const flashType = ref<FlashMessageType>('primary');
  let timeout;
  if (Boolean(options.seconds)) {
    watch([flashText, flashSeconds, flashType], () => {
      if (flashText.value) {
        clearTimeout(timeout);
        timeout = setTimeout(() => flashText.value = '', 1000 * flashSeconds.value);
      }
    });
  }
  return { 
    flashText,
    flashSeconds,
    flashType,
    clearTimer: () => nextTick(() => clearTimeout(timeout))
  }
}

interface UseFlashMessageOptions {
  seconds: number;
}
interface FlashMessage {
  flashText: Ref<string>,
  flashType: Ref<FlashMessageType>;
  flashSeconds: Ref<number>;
  clearTimer: () => void;
}

type FlashMessageType = 'primary' | 'warning'