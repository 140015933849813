import { Controller } from "@hotwired/stimulus";
import debounce from 'lodash/debounce';

export default class extends Controller {
  static targets = ["schedulerForm", "scheduleDate"];

  connect() {
    this.debouncedFormSubmitter = debounce(() => this.schedulerFormTarget.requestSubmit(), 500);
  }

  fetchSchedulePreviousWeek(e) {
    e.preventDefault();
    this.scheduleDateTarget.value = this.getAdjustedScheduleDateStringValue(-7);
    this.debouncedFormSubmitter();
  }

  fetchScheduleNextWeek(e) {
    e.preventDefault();
    this.scheduleDateTarget.value = this.getAdjustedScheduleDateStringValue(7);
    this.debouncedFormSubmitter();
  }

  // Returns new date string adjusted by days in YYYY-Mmm-DD format
  getAdjustedScheduleDateStringValue(days) {
    const weeklyFormDate = new Date(this.scheduleDateTarget.value || new Date());
    weeklyFormDate.setDate(weeklyFormDate.getDate() + days);
    return this.formatDate(weeklyFormDate);
  }

  // Method to format date to "2024-Feb-02"
  formatDate(date) {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const month = date.toLocaleDateString('en-CA', { month: 'short' });
    const day = date.toLocaleDateString('en-CA', { day: '2-digit' });
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  disconnect() {
    // Any cleanup logic if necessary
  }
}
