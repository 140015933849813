import { Controller } from "@hotwired/stimulus";
import subscribeToWalkIns from "../../../javascript/channels/emr_channels/walk_ins_channel"
import { consumer } from "../../../javascript/channels/consumer"

export default class extends Controller {
  static values = { clinicId: Number }

  connect() {
    this.subscription = subscribeToWalkIns(this.clinicIdValue);
  }

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
      this.subscription = null;
    }
  }
}
