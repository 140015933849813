export function replaceListItemById(list, item) {
  return list.map((listItem) => listItem.id === item.id ? item : listItem);
}

export async function withLoader(
  this: any,
  requestAction: () => Promise<any>,
  loadingKey: string = 'isLoading'
) {
  this[loadingKey] = true;
  try {
    await requestAction();
  } catch (error) {
    console.error(error);
  } finally {
    this[loadingKey] = false;
  }
}