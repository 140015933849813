import { computed } from 'vue'

export function useModelWrapper(
  props, 
  emit, 
  name = 'modelValue', 
  valueManipulator?: (v: any) => any
) { 
  return computed({ 
    get: () => props[name], 
    set: (value) =>
      emit(
        `update:${name}`,
        valueManipulator ? valueManipulator(value) : value
      ),
  })
}
 