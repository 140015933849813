import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["category", "subcategory"];

  connect() {
    console.log("Connected to category controller");
  }

  updateSubcategories() {
    console.log("updating subcategories")
    const selectedCategory = this.categoryTarget.value;
    const allSubcategories = JSON.parse(this.element.getAttribute("data-category-subcategory-options"));
    console.log(allSubcategories);
    const subcategoriesForSelectedCategory = allSubcategories[selectedCategory] || [];
    const options = [
      '<option value=""></option>',
      ...subcategoriesForSelectedCategory.map(option => `<option value="${option}">${option}</option>`)
    ];
  
    this.subcategoryTarget.innerHTML = options.join("");
  }
}
