import { Extension } from '@tiptap/core';
import { TextSelection } from 'prosemirror-state';
import { retrieveDynamicMacroNodeNearCursor } from '../editor_helpers';
import { 
  moveMultiSelectFocus,
  focusConfirmBox,
  isConfirmBoxFocused,
  selectFocussedMacro,
  getNewPhrase,
  MacroMultiSelectService,
} from '../../../../javascript/src/custom-modules/macros/macro-dom-helpers';

import { handleTabbingThroughDynamicMacroNodes } from '../dynamic_macro_controls_helpers';

const macroService = new MacroMultiSelectService();

export const DynamicMacroControlsExtension = Extension.create({
  name: 'dynamicMacroControlsExtension',

  addKeyboardShortcuts() {
    return {
      'Tab': () => handleTabbingThroughDynamicMacroNodes({editor: this.editor, isReverse: false, macroService}),
      'Shift-Tab': () => handleTabbingThroughDynamicMacroNodes({editor: this.editor, isReverse: true, macroService}),
      'Space': () => {
        if (macroService.isOpen) {
          selectFocussedMacro();
          return true
        }
        return false
      },
      'Backspace': () => {
        const { node, position } = retrieveDynamicMacroNodeNearCursor(this.editor);

        if (node && node.type.name === 'dynamicMacroNode') {
          // Create a transaction to delete the node
          const transaction = this.editor.state.tr.delete(position, position + node.nodeSize);
          this.editor.view.dispatch(transaction);
          macroService.removeMenu();
          return true;
        }

        return false;
      },
      'ArrowDown': () => {
        if (macroService.isOpen) {
          moveMultiSelectFocus(1)
          return true
        }
        return false
      },
      'ArrowUp': () => {
        if (macroService.isOpen) {
          moveMultiSelectFocus(-1)
          return true
        }
        return false
      },
      'ArrowRight': () => {
        if (macroService.isOpen && !isConfirmBoxFocused()) {
          focusConfirmBox(true);  
          return true
        }
        return false
      },
      'ArrowLeft': () => {
        if (macroService.isOpen && isConfirmBoxFocused()) {
          focusConfirmBox(false);
          return true
      }
        return false
      },
      'Enter': () => {
        if (macroService.isOpen) {
          const phrase = getNewPhrase();
          const { node, position } = retrieveDynamicMacroNodeNearCursor(this.editor)

          if (!node || !position || !phrase) {
            macroService.removeMenu();
            return true;
          } 

          const {schema, tr } = this.editor.state;
          const from = position;
          const to = position + node.nodeSize;

          const textNode = schema.text(phrase);
          tr.replaceWith(from, to, textNode);

          const newPos = from + textNode.nodeSize;
          const selection = TextSelection.create(tr.doc, newPos);
          tr.setSelection(selection);

          this.editor.view.dispatch(tr);
          macroService.removeMenu();
          return true
        }
      },
    };
  },
  /**
   * runs everytime the selection on the editor changes
   */
  onSelectionUpdate: ({ editor }) => {
    const { node } = retrieveDynamicMacroNodeNearCursor(editor);
    if (node) {
      return macroService.launchMacroWindow(editor, node.attrs.dataMacroText)
    } else if (macroService.isOpen) {
      macroService.removeMenu();
    }
  },
});