import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["drawerCardContent", "dropDownButton"]

  toggleDrawerCard() {
    if (this.drawerCardContentTarget.classList.contains("hidden-field")) {
      this.drawerCardContentTarget.classList.remove("hidden-field")
      this.dropDownButtonTarget.classList.remove("drawer-card-rotate-180")
    }
    else {
      this.drawerCardContentTarget.classList.add("hidden-field")
      this.dropDownButtonTarget.classList.add("drawer-card-rotate-180")
    }

  }
}