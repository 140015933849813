<template>
  <div class="base-select-container">
    <base-typography formLabel body> {{ label }} </base-typography>
      <select 
      v-bind="$attrs"
      :value="modelValue"
      @change="$emit('update:modelValue', selectEventHandler($event))"
      >
      <option disabled value="" selected >{{ placeholder }}</option>
      <slot></slot>
    </select>
  </div>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';

defineProps(['modelValue', 'label', 'placeholder'])
defineEmits(['update:modelValue'])

const selectEventHandler = (event: Event): string => {
  return (event.target as HTMLSelectElement).value
}

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped></style>