<template>
  <div class="base-checkbox-container">
    <slot>
      <base-typography formLabel body> {{ label }} </base-typography>
    </slot>    
    <input
      class="base-checkbox"
      data-test="checkbox"
      type="checkbox"
      :checked="modelValue"
      @input="updateModelValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseTypography from '../base-typography/base-typography.vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const updateModelValue = (event) => {
      context.emit("update:modelValue", event.target.checked);
    };
    return {
      updateModelValue
    };
  },
  components: { BaseTypography }
});
</script>

<style scoped lang="scss">
  .base-checkbox-container {
    display: flex;
    align-items: center;
    gap: 1em;
    .base-checkbox {
      margin: 0px;
    }
  }
</style>