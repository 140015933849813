import { consumer, isSubscribed } from "../consumer"

function subscribeToGenerateTextChannel(nonce) {
  consumer.subscriptions.create(
    { channel: "EmrChannels::GenerateTextChannel", nonce: nonce },
    {
      connected() {
        console.log("Connected to GenerateTextChannel:", nonce);
      },

      disconnected() {
        console.log("Disconnected from GenerateTextChannel:", nonce);
      },

      received(data) {
        // Handle received data here
        console.log("Received data:", data);
        let jqTarget = $(`[data-nonce="${nonce}"]`)
        // Set the text data
        jqTarget.find('.text-generator-target').val(data['text'])
        // Set the name if the name is blank
        let nameField = jqTarget.find('.text-generator-name')
        if (!!nameField && !nameField.val() && !!data["name"]) {
          jqTarget.find('.text-generator-name').val(data['name'])
        }
        // Reset the Ava Assist Button
        jqTarget.find('.text-generator-pending-message').hide()
        jqTarget.find('.text-generator-button').show()
        // Disconnect from channel
        
      },
    }
  );
}

export default subscribeToGenerateTextChannel;
