<template>
  <div class="form-editor-≈-container">
    <base-typography v-if="label" formLabel capitalize>{{ label }}</base-typography>
    <base-typography v-if="errorMessage" warning>{{errorMessage}}</base-typography>
    <div class="textarea-row-container">
      <i v-if="showPencil" class="fa fa-pencil" />
      <base-textarea         
        class="form-template-editor-textarea" 
        v-model="value"
      />
    </div>
  </div>
  
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { useModelWrapper } from '@/composition/use-model-wrapper';
import BaseTextarea from '@/ui-library/base-textarea/base-textarea.vue';
import { onMounted, ref } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  errorMessage: {
    type: String,
    required: false
  },
  showPencil: {
    type: Boolean,
    default: true
  },
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    required: false,
    default: ''
  }
});

const value = useModelWrapper(props, emit);

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.form-editor-textarea-container {
  .textarea-row-container {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
    .form-template-editor-textarea {
      margin: 10px;
      width: inherit;
      background-color: white;
      border: none;
      border-bottom: 1px solid $ava-border-color;
      min-height: 100px !important;
    }
  }
}
</style>