import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["index"];

  connect() {
    this.initializeVisibility();
  }

  toggle() {
    const isHidden = this.indexTarget.classList.toggle('hidden-field');
    Cookies.set('roomsVisibility', isHidden ? 'hidden' : 'visible', { expires: 7, path: '/' });
  }

  initializeVisibility() {
    const visibility = Cookies.get('roomsVisibility');
    if (visibility === 'hidden') {
      this.indexTarget.classList.add('hidden-field');
    } else {
      this.indexTarget.classList.remove('hidden-field');
    }
  }
}
