<template>
  <div class="create-patient-form-template-container">
    <div class="title-row">
      <base-typography title class="ava-form-title">My Ava eForm Templates</base-typography>
      <base-button @click="toggleForm"> new </base-button>
    </div>
    <base-transition-expand>
      <div class="start-template-title-form" v-if="isFormOpen">
        <base-label description="Start by naming your form:">
          <base-input v-focus v-model="templateName" type="text" :warningLabel="warningLabel" />
        </base-label>
        <base-button class="create-form-button" @click="submit" primary> Create form </base-button>
      </div>
    </base-transition-expand>
  </div>
</template>

<script setup lang="ts">

import BaseInput from '@/ui-library/base-input/base-input.vue';
import BaseLabel from '@/ui-library/base-label/base-label.vue';
import BaseButton from '@/ui-library/base-button/base-button.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { useToggledValue } from '@/composition/use-toggled-value';
import BaseTransitionExpand from '@/ui-library/base-transition/base-transition-expand.vue';
import { computed, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue', 'submit'])

const warningLabel = ref('');

const templateName = computed({
  get () {
    warningLabel.value = '';
    return props.modelValue;
  },
  set (value) {
    return emit('update:modelValue', value);
  }
})

const submit = () => {
  if (props.modelValue) {
    emit('submit')
  } else {
    warningLabel.value = "required";
  }
}

const { isTrue: isFormOpen, toggleValue: toggleForm } = useToggledValue()



</script>

<style lang="scss" scoped>
  .create-patient-form-template-container {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    .title-row {
      display: flex;
      gap: 1em;
      align-items: center;
      .ava-form-title {
        text-transform: none;
      }
    }
    .start-template-title-form {
      display: flex;
      flex-direction: column;
      gap: 1em;
      .create-form-button {
        max-width: 200px;
      }
    }
  }
</style>