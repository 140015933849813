import { usePatientChartStore } from '../../features/patient-chart/store/use-patient-chart-store';
import { GlobalStateFacade } from './global-state-facade.interface';

/**
 * In order to couple vue app and rails app we use a globalStateFacade that
 * is available on the global instance. The methods on the facade will trigger
 * state change that will affect components listening to the state.
 * 
 * No "logic" should exist here 
 * createGlobalStateFacade methods should only use stores and their methods  
 * use eg.: window.globalStateFacade.patientChart.openVideoLink()
 */

export function createGlobalStateFacade(): GlobalStateFacade {
  return {
    patientChart: {
      openVideoLink() {
        const store = usePatientChartStore();
        store.toggleVideoLink(true);
      },
    },
  };
}
