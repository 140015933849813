<template>
  <base-typography formLabel capitalize>
    Form Item Type: <strong>{{ category }}</strong>
  </base-typography>
  <form-editor-input 
    :errorMessage="errorMessageState['label']"  
    label="form item title/question" 
    v-model="label" 
  />
  <form-editor-input 
    :errorMessage="errorMessageState['labelAlias']"  
    label="form item internal alias" 
    v-model="labelAlias"
  />
  <form-editor-input label="description" v-model="help" />
  <base-checkbox label="Question is required?" v-model="required" />
  <form-editor-conditional-section
    v-model="display" 
    :liteFormItems="liteFormItemsComputed" 
  />
</template>

<script setup lang="ts">

import { computed, PropType } from 'vue';
import BaseCheckbox from '@/ui-library/base-checkbox/base-checkbox.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import FormEditorInput from './form-editor-input.vue';
import { useModelWrapper } from '@/composition/use-model-wrapper';
import { FormTemplateErrorMessagesState } from '@/features/custom-form-templates/types/patient-form-template-details';
import FormEditorConditionalSection from './conditional-components/form-editor-conditional-section.vue';
import { FlattenedLiteFormItem } from '../../../../../../../types/patient-form-lite-form-item';

const emit = defineEmits([
  'update:label', 
  'update:help',
  'update:multiple',
  'update:required',
  'update:display',
  'update:labelAlias'
])
const props = defineProps({
  category: String,
  label: String,
  labelAlias: String,
  help: String,
  multiple: Boolean,
  required: {
    type: Boolean,
    required: true,
    default: false
  },
  display: {
    type: [Boolean, Object]
  },
  errorMessageState: {
    required: true,
    type: Object as PropType<FormTemplateErrorMessagesState | {}>
  },
  liteFormItems: {
    required: true,
    type: Object as PropType<FlattenedLiteFormItem[]>,
    default: () => []
  },
  formId: {
    required: true,
    type: String,
  }
})

const label = useModelWrapper(props, emit, 'label');
const labelAlias = useModelWrapper(props, emit, 'labelAlias');
const help = useModelWrapper(props, emit, 'help');
const required = useModelWrapper(props, emit, 'required');
const display = useModelWrapper(props, emit, 'display');
const liteFormItemsComputed = computed(() => props.liteFormItems.filter(i => i.id !== props.formId))

</script>
