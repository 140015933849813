import { AxiosResponse } from 'axios';

const createHeaderForXMLHttpRequest = () => ({ 
  'X-Requested-With': 'XMLHttpRequest'
});

const fulfillXmlHttpRequest = (response: AxiosResponse) => {
  Function(`"use strict";${response.request.responseText}`)();
}

const isResponseSuccessful = (response: AxiosResponse) => {
  return response.status > 199 && response.status < 300
}

export default {
  createHeaderForXMLHttpRequest,
  fulfillXmlHttpRequest,
  isResponseSuccessful
};