// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component-name (ie. shared-ui--checkmark-icon) in the component class.
//
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {

  open() {
    window.globalStateFacade.patientChart.openVideoLink();
  }
  
}
