// add an onclick event to the element that will
// trigger the toggle action that toggles other elements
// using the .hidden class

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["toggleable"]

    toggle() {
        this.toggleableTargets.forEach((element) => {
            element.classList.toggle('hidden');
        });
    }
}