<template>
  <form-editor-card>
    <template #header>
      <form-editor-card-header tabIndex="0">
        <base-typography bold capitalize>new form item in section</base-typography>
        <i class="fa fa-plus" /> 
      </form-editor-card-header>
    </template>
    <template #body="{ closeDetails }">
      <base-select v-model="formItemType">
        <option :value="category" :key="category" v-for="category in allowedCategories"> 
          {{translateCategory(category)}}
        </option>
      </base-select>
      <select-form-item 
        v-show="formItemType === 'select'" 
        :autoSave="false"
        @close="closeDetails"
        @submit="(value) => save(closeDetails)(value)"
        :item="createDefaultSelectFormItem({ order: nextOrderValue })"/>
      <date-form-item 
        v-show="formItemType === 'date'" 
        :autoSave="false"
        @close="closeDetails"
        @submit="(value) => save(closeDetails)(value)"
        :item="createDefaultDateFormItem({ order: nextOrderValue })"/>
      <number-form-item 
        v-show="formItemType === 'number'" 
        :autoSave="false"
        @close="closeDetails"
        @submit="(value) => save(closeDetails)(value)"
        :item="createDefaultNumberFormItem({ order: nextOrderValue })" />
      <text-form-item 
        v-show="formItemType === 'text'" 
        :autoSave="false"
        @close="closeDetails"
        @submit="(value) => save(closeDetails)(value)"
        :item="createDefaultTextFormItem({ order: nextOrderValue })" />      
    </template> 
  </form-editor-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BaseSelect from '@/ui-library/base-select/base-select.vue';
import { PATIENT_FORM_OBJECT_CATEGORIES } from '@/features/custom-form-templates/types/patient-form-object-categories';
import { 
  createDefaultSelectFormItem, 
  createDefaultDateFormItem,
  createDefaultNumberFormItem,
  createDefaultTextFormItem
} from '@/features/custom-form-templates/types/patient-form-template-details.functions';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import SelectFormItem from './form-item-components/select-form-item.vue';
import FormEditorCard from './form-editor-card.vue';
import DateFormItem from './form-item-components/date-form-item.vue';
import NumberFormItem from './form-item-components/number-form-item.vue';
import TextFormItem from './form-item-components/text-form-item.vue';
import FormEditorCardHeader from '../../../components/form-editor-card-header.vue';

const allowedCategories = PATIENT_FORM_OBJECT_CATEGORIES.filter(c => !['group', 'header', 'checkbox'].includes(c))
const formItemType = ref<typeof allowedCategories[number]>(allowedCategories[0]);

const emit = defineEmits(['submit', 'toggleAddQuestionForm', 'close']);

defineProps({
  isAddQuestionFormOpen: {
    default: false,
    type: Boolean
  },
  nextOrderValue: {
    default: 0,
    type: Number
  }
})
const save = (closeDetails: () => void) => {
  closeDetails();
  return (value) => {
    emit('submit', value)
  };
}

const translateCategory = (category: typeof allowedCategories[number]) => {
  switch (category) {
    case 'number':
      return 'Number: Answers will be integers eg. -1, 0, 1, 2'
    case 'date':
      return 'Date: Answers will be in the format: YYYY-MM-DD'
    case 'select':
      return 'Select: Answers will be a listed value'
    case 'text':
      return 'Text: Answers will be free form text'
    default:
      return category
  }
}

</script>

<style lang="scss" scoped>
.new-form-item-header {
  padding: 1em;
  display: flex;
  align-items: center;
  gap: 1em;
}
</style>