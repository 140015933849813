<template>
  <base-button v-if="!isAddRuleFormOpen" @click="toggleAddRuleForm"> 
    add rule
  </base-button>
  <base-transition-expand>
    <div v-if="isAddRuleFormOpen" class="new-value-rule-container">
      <base-select v-model="formItemModel" class="ava-col-12">
        <option v-for="i in liteFormItems" :key="i.id" :value="i.id"> 
          {{ i.breadcrumbs }}
        </option>
      </base-select>
      <div class="new-value-rule-form-item-row ava-col-12">
        <base-select v-model="operatorModel" class="operator">
          <option v-for="operator in operatorList" :key="operator"> 
            {{operator}}
          </option>
        </base-select>
        <base-input
          v-model="valueModel" 
          :placeholder="operatorIsNumberOnly ? 'only integers are accepted' : ''" 
          :type="operatorIsNumberOnly ? 'number' : 'text'" 
          :disabled="!operatorRequiresInput"
        />      
      </div>
      <base-typography v-if="ruleError" warning> {{ ruleError }} </base-typography>
      <div class="button-row">
        <base-button warning @click="toggleAddRuleForm">close</base-button>
        <base-button primary @click="submit">submit</base-button>
      </div>
    </div>
  </base-transition-expand>
</template>

<script setup lang="ts">
import BaseButton from '@/ui-library/base-button/base-button.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseInput from '@/ui-library/base-input/base-input.vue';
import BaseSelect from '@/ui-library/base-select/base-select.vue';
import BaseTransitionExpand from '@/ui-library/base-transition/base-transition-expand.vue';
import { computed, PropType, ref, watch } from 'vue';
import { FlattenedLiteFormItem } from '../../../../../../../../types/patient-form-lite-form-item';
import { ConditionalFormDetailRule } from '../../../../../../../../types/patient-form-template-details';

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  liteFormItems: {
    required: true,
    type: Object as PropType<FlattenedLiteFormItem[]>,
    default: () => []
  },
  modelValue: {
    default: () => [],
    type: Array as PropType<ConditionalFormDetailRule[]>
  },
})
const equalOperatorList = ['=', '!='];
const numberOperatorList = ['>', '<', '>=', '<='];
const presenceOperatorList = ['present', 'blank'];
const operatorList = [...numberOperatorList, ...equalOperatorList, ...presenceOperatorList];

const formItemModel = ref(props.liteFormItems[0]?.id || '');
const operatorModel = ref('present');
const valueModel = ref('');
const ruleError = ref('');
const isAddRuleFormOpen = ref(false);

const operatorIsNumberOnly = computed(() => numberOperatorList.includes(operatorModel.value));
const operatorRequiresInput = computed(() => [...equalOperatorList, ...numberOperatorList].includes(operatorModel.value));

const toggleAddRuleForm = () => {
  isAddRuleFormOpen.value = !isAddRuleFormOpen.value
}

const checkRuleFormForSubmission = () => {
  if (!formItemModel.value) {
    ruleError.value = 'a form item must be selected';
  } else if (operatorRequiresInput.value) {
    ruleError.value = !valueModel.value ? 'value cannot be blank' : '';
  } else if(!operatorRequiresInput.value) {
    ruleError.value = valueModel.value ? 'value must be blank' : '';
  }
}

const resetForm = () => {
  operatorModel.value = 'present';
  valueModel.value = '';
  formItemModel.value = props.liteFormItems[0]?.id || '';
}

const submit = () => {
  checkRuleFormForSubmission();
  if (!ruleError.value) {
    emits('update:modelValue', [...props.modelValue, { 
      field_id: formItemModel.value,
      operator: operatorModel.value,
      value: operatorRequiresInput.value ? valueModel.value || '0' : ''      
    }])
    resetForm();
  }
}

watch(operatorModel, () => {
  if (presenceOperatorList.includes(operatorModel.value)) {
    valueModel.value = ''
  }
})


</script>

<style lang="scss" scoped>
  .new-value-rule-container {
    .new-value-rule-form-item-row {
      display: flex;
      gap: 1em;
      .operator {
        width: auto;
      }
    }
    .button-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1em;
    }
  }
</style>