import { generatePlainTextTextareaSelector } from "./macro-text-helpers";

$(document).on('keyup', generatePlainTextTextareaSelector(), function(event) {
  const el = event.target;
  let text = el.value;
  const cursorPosition = el.selectionStart;
  const substring = text.substring(0, cursorPosition);
  const array = substring.match(/;?\w+/g);
  const keyword = array && array.length ? array[array.length - 1] : null;

  if (keyword && keyword[0] === ';') {
    const macros = JSON.parse(localStorage.getItem('keyShortcuts'));
    const jq = $(this);
    const jqueryField = this;
    const macroText = macros[keyword];
    if (macroText) {
      let caretPos = jqueryField.selectionStart;
      text = text.replace(keyword, macroText);
      jq.val(text);
      let moveCursorTo;
      if (macroText.includes('<<')) {
        moveCursorTo = caretPos - keyword.length;
      } else {
        moveCursorTo = caretPos + macroText.replace(/\n/g, "").length - keyword.length;
      }
      jqueryField.selectionStart = moveCursorTo;
      jqueryField.selectionEnd = moveCursorTo;
      autosize.update(jq);
    }
  }
});