import { mergeAttributes, Node } from '@tiptap/core'

const dynamicMacroClass = 'rich-textbox-dynamic-macro-node'

export default Node.create({
  name: 'dynamicMacroNode',

  inline: true,
  group: 'inline',
  content: 'text*', 

  addAttributes() {
    return {
      dataMacroText: {
        default: '',
      },
    }
  },

  atom: true,

  parseHTML() {
    return [
      {
        tag: `span.${dynamicMacroClass}`
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, {
      class: dynamicMacroClass
    }), 0];
  },

  addNodeView() {
    return ({ node }) => {
      const dataMacroText = node.attrs.dataMacroText;
      const dom = document.createElement('span')
      dom.classList.add(dynamicMacroClass)
      dom.textContent = dataMacroText
      return {
        dom,
      }
    }
  },
})