import { TextSelection } from "@tiptap/pm/state";
import { removeMacroMultiSelect } from "../../../javascript/src/custom-modules/macros/macro-dom-helpers";
import { dynamicMacroRegex, moveCursorToEnd } from "./editor_helpers";

export const handleTabbingThroughDynamicMacroNodes = ({ editor, isReverse, macroService }) => {
  return editor.commands.command(({ tr, dispatch }) => {
    const { doc, selection } = editor.state;
    const { from } = selection;
    
    // Find all matches in the document
    const matches = [];
    doc.descendants((node, pos) => {
      if (node.type.name === 'dynamicMacroNode') {
        const match = dynamicMacroRegex.exec(node.attrs.dataMacroText);
        if (match) {
          matches.push({ from: pos + match.index, to: pos + match.index + match[0].length, text: match[0] });
        }
      }
    });
    if (isReverse) {
      matches.reverse()
    }

    // Find the next match after the current selection    
    const nextMatch = matches.find(match => isReverse ? match.from < from : match.from > from) || matches[0] || null;
    
    if (nextMatch) {
      if (macroService.isLastMacroInTextarea) {
        /**
         * If isLastMacroInTextarea is set to false it allows cursor to loop through with another tab.
         * If isLastMacroInTextarea is set to true it allows cursor to continue as normal on tab
         */
        macroService.isLastMacroInTextarea = false; 
        moveCursorToEnd(tr, doc, dispatch);
        macroService.removeMenu();
        return true;
      }
      const nextSelection = TextSelection.create(doc, nextMatch.from, nextMatch.from);
      dispatch(tr.setSelection(nextSelection));
      if (nextMatch.from === matches[matches.length - 1].from) {
        macroService.isLastMacroInTextarea = true;
      }
      return true;
    } 
    removeMacroMultiSelect();
    return false; // Indicate that the command was not handled (Tab acts normally)
  })
} 