import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggeable"]
  static classes = ["active", "inactive"]

  toggle(event) {
    if (event.target.classList.contains(this.inactiveClass)) {
      this.toggeableTarget.querySelector(`.${this.activeClass}`).classList.add(this.inactiveClass) 
      this.toggeableTarget.querySelector(`.${this.activeClass}`).classList.remove(this.activeClass)

      event.target.classList.remove(this.inactiveClass)
      event.target.classList.add(this.activeClass)
    }
  }

}