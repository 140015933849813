import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "limitToGroupsContainer", "selectedGroups", "groupsModal", "groupsModalOverlay"]

  connect() {
    if (this.limitToGroupsSelected()) {
      this.showLimitToGroupsContainer();
    }
    this.selectedGroups = new Set();
    this.addGroups();
  }

  openModal(event) {
    if (event) {
      event.preventDefault();
    }
    this.groupsModalOverlayTarget.classList.remove("hidden-field");
    this.groupsModalTarget.classList.remove("hidden-field");
  }

  closeModal(event) {
    if (event) {
      event.preventDefault();
    }
    this.groupsModalOverlayTarget.classList.add("hidden-field");
    this.groupsModalTarget.classList.add("hidden-field");
  }

  limitToGroupsSelected() {
    return this.fieldTarget.value == 'limit_to_groups';
  }

  fieldChange() {
    if (this.limitToGroupsSelected()) {
      this.showLimitToGroupsContainer();
      this.addGroups();
    } else {
      this.hideLimitToGroupsContainer();
    }
  }

  showLimitToGroupsContainer() {
    if (this.limitToGroupsContainerTarget) {
      this.limitToGroupsContainerTarget.classList.remove('hidden-field');
    }
  }

  hideLimitToGroupsContainer() {
    if (this.limitToGroupsContainerTarget) {
      this.limitToGroupsContainerTarget.classList.add('hidden-field');
    }
  }

  addGroups(event) {
    const checkboxes = this.groupsModalTarget.querySelectorAll('.permission-group-checkbox:checked');
    this.selectedGroups = new Set(Array.from(checkboxes).map(cb => cb.dataset.name));
    if (this.selectedGroups.size < 1) {
      this.selectedGroups.add('None Selected');
    }
    this.updateSelectedGroupsDisplay();
    this.closeModal(event);
  }

  updateSelectedGroupsDisplay() {
    const selectedGroupsArray = Array.from(this.selectedGroups);
    this.selectedGroupsTarget.innerHTML = selectedGroupsArray.slice(0, 3).join(", ") + (selectedGroupsArray.length > 3 ? "..." : "");
    this.selectedGroupsTarget.title = selectedGroupsArray.join(", ");
  }

}
