import { createRouter, createWebHistory } from 'vue-router';
import NewCustomFormTemplate from '../features/custom-form-templates/features/new-custom-form-template/new-custom-form-template.vue';
import EditCustomFormTemplate from '../features/custom-form-templates/features/edit-custom-form-template/edit-custom-form-template.vue';

const routes = [
  { path: '/patient_form_templates/new', component: NewCustomFormTemplate, name: 'patient-form-templates-new' },
  { path: '/patient_form_templates/:id', component: EditCustomFormTemplate, name: 'patient-form-template' }
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
