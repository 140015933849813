import { consumer, isSubscribed } from "../consumer"

$(document).ready(function() {
  let openThread = $('.footer-messenger-pin').find('.footer-thread-container:visible')
  const threadId = openThread.closest('.footer-messenger-pin').data('thread-id')
  unsubscribeFromThread(threadId)
  subscribeToThread(threadId)
});

$(document).on('click', '.subscribe-to-thread', function() {
  const threadId = $(this).closest('.footer-messenger-pin').data('thread-id')
  subscribeToThread(threadId)
});

$(document).on('click', '.unsubscribe-from-thread', function() {
  const threadId = $(this).closest('.footer-messenger-pin').data('thread-id')
  unsubscribeFromThread(threadId)
});

const subscribeToThread = function(threadId) {
  // In some cases the JS can have a blank threadId and throw an error on the
  // subscribe to channel list
  if (!threadId) {
    return true
  }
  let subscriptionObject = {
    channel: 'EmrChannels::InstantMessengerThreadChannel',
    thread_id: threadId
  }
  let pin = $(`.footer-messenger-pin[data-thread-id='${threadId}']`)
  let content = pin.find('.instant-message-content')
  if (isSubscribed(subscriptionObject)) {
    return true
  }
  consumer.subscriptions.create(
    subscriptionObject,
    {
      initialized() {
        // console.log('initializing')
        // this.update = this.update.bind(this)
      },
      received(data) {
        let currentEmployeeId = parseInt($('#messenger-index-button').data('employee-id'))
        let messageEmployeeId = parseInt(data["employee_id"])
        let htmlContent = data["html"]

        if (currentEmployeeId != messageEmployeeId) {
          htmlContent = htmlContent.replace('right-bubble', 'left-bubble')
        }

        if (content.find('.messages-index').length > 0) {
          content.append(
            htmlContent
          ).animate({
            scrollTop: content.prop('scrollHeight')
          }, 200);
        }

        // Mark message as read if thread is open
        if (content.is(':visible')) {
          $.ajax({
            url: `/instant_messenger/mark_message_as_reads/${data["message_id"]}`,
            data: {
              thread_id: threadId
            },
            method: 'PUT'
          });
        }
      },

      // Call when subscription is ready for use on the server
      connected() {
        console.log(`InstantMessengerThreadChannel Connected! imt_${threadId}`)
      },

      // Called when the WebSocket connection is closed
      // disconnected() {
      //   this.uninstall()
      // },

      // // Called when the subscription is rejected by the server
      // rejected() {
      //   this.uninstall()
      // },

      // update() {
      //   this.documentIsActive ? this.appear() : this.away()
      // },

      // appear() {
      //   // Calls 'ClinicSessionStatusChannel#appear(data)' on the server
      //   this.perform("appear", { appearing_on: this.appearingOn })
      // },

      // away() {
      //   this.perform("away")
      // },

      // install() {
      //   window.addEventListener("focus", this.update)
      //   window.addEventListener("blur", this.update)
      //   window.addEventListener("turbolinks:load", this.update)
      //   window.addEventListener("visibilitychange", this.update)
      // },

      // uninstall() {
      //   window.removeEventListener("focus", this.update)
      //   window.removeEventListener("blur", this.update)
      //   document.removeEventListener("turbolinks:load", this.update)
      //   document.removeEventListener("visibilitychange", this.update)
      // },

      // get documentIsActive() {
      //   return document.visibilityState === "visible" && document.hasFocus()
      // },

      // get appearingOn() {
      //   const element = document.querySelector("[data-appearing-on]")
      //   return element ? element.getAttribute("data-appearing-on") : null
      // }
    }
  );
};

const unsubscribeFromThread = function(threadId) {
  let channelId = `{\"channel\":\"EmrChannels::InstantMessengerThreadChannel\",\"thread_id\":${threadId}}`
  let subscriptions = consumer.subscriptions.findAll(channelId)
  subscriptions.forEach(subscription => consumer.subscriptions.remove(subscription))
  console.log('unsubscribed from', threadId)
};

function threadIsOpen(threadId) {
  $('.footer-')
}

function scrollToTop() {

};