export const autoFocusDirective = {
  mounted(el) {
    const nodeName = el.nodeName.toLowerCase();
    if ( /input|select|textarea|button/.test(nodeName)) {
      el.focus()
    } else {
      const focusables = el.querySelectorAll('button, input, select, textarea')
      focusables[0]?.focus();      
    }
  }
}