/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')
// require("@rails/ujs").start() // only once the sprockets are all migrated over
// require("turbolinks").start()
// require("channels")


import $ from 'jquery';
import * as ActiveStorage from "@rails/activestorage"
/**
 * import "core-js/features/promise"; import "regenerator-runtime/runtime";
 * are required to prevent runtime error
 * https://stackoverflow.com/questions/53558916/babel-7-referenceerror-regeneratorruntime-is-not-defined
 */
import "core-js/features/promise";
import "regenerator-runtime/runtime";
import 'src/custom-modules';
import "./application.css";
import { initVue } from 'src/init-vue-app';
import { initPlainTextDynamicMacros } from 'src/custom-modules/macros/init-plain-text-dynamic-macros';
import { initMacroContextMenu } from 'src/custom-modules/macros/macro-context-menu/macro-context-menu';
import { initTranscriber } from 'src/custom-modules/transcriber/index';
import TextGenerator from 'src/custom-modules/text-generator/index';
// needs to be imported last
import "../components";
import '../controllers';

require('channels/emr_channels/clinic_session_status_channel');
require('channels/emr_channels/instant_messenger_thread_channel');
require('channels/emr_channels/employee_instant_messenger_channel');
require('channels/emr_channels/generate_text_channel');
require('channels/emr_channels/schedule_channel');
require('channels/emr_channels/room_channel');

ActiveStorage.start()
window.$(document).foundation();
initVue($)
initPlainTextDynamicMacros();
initMacroContextMenu();
initTranscriber($)
window.TextGenerator = TextGenerator;
