<template>
  <div class="conditional-rule-manager">
    <div v-if="rules.length" class="rules-list-container">
      <div class="rules-list ava-col-8">
        <base-typography v-if="rules?.length" capitalize title> rules: </base-typography>
        <div class="list-item" :key="index" v-for="item, index in rules">
          <base-typography body>{{index + 1}}</base-typography>
          <base-typography body>{{getLabelForFormItem(item.field_id)}}</base-typography>
          <base-typography body>{{item.operator}}</base-typography>
          <base-typography v-if="item.value" body>{{item.value}}</base-typography>
          <base-button-icon @click="deleteItem(item)">
            <i class="fa fa-times"/>
          </base-button-icon>
        </div>
      </div>
      <div v-if="rules.length > 1" class="ava-col-4">
        <slot name="rule-combination-type" /> 
      </div>
    </div>
    <base-typography v-else body>
      You have no rules yet. Add rules for when you want this form item to display
    </base-typography>
    <conditional-rule-form
      :liteFormItems="liteFormItems" 
      v-model="rules"
    />
  </div> 
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import { ConditionalFormDetailRule } from '@/features/custom-form-templates/types/patient-form-template-details';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import isEqual from 'lodash/isEqual';
import ConditionalRuleForm from './conditional-rule-form.vue';
import { FlattenedLiteFormItem } from '../../../../../../../../types/patient-form-lite-form-item';
import { useModelWrapper } from '../../../../../../../../../../composition/use-model-wrapper';

const emits = defineEmits(['update:rules'])

const props = defineProps({
  errorMessage: {
    type: String,
    required: false
  },
  rules: {
    default: () => [],
    type: Array as PropType<ConditionalFormDetailRule[]>
  },
  liteFormItems: {
    required: true,
    type: Object as PropType<FlattenedLiteFormItem[]>,
    default: () => []
  }
})

const rules = useModelWrapper(props, emits, 'rules');

const deleteItem = (rule: ConditionalFormDetailRule) => {
  emits('update:rules', props.rules.filter((o) => !isEqual(rule, o)));
}

const getLabelForFormItem = (formItemId: string) => {
  const item = props.liteFormItems.find((i) => i.id == formItemId)
  return item?.breadcrumbs || formItemId;
}

</script>

<style lang="scss" scoped>
.conditional-rule-manager {
  .rules-list-container {
    display: flex;
    gap: 1em;
    flex-wrap: nowrap;
    margin: 2em 0em;
    padding-left: 2em;
  }
  .list-item {
    display: flex;
    align-items: center;
    gap: 2em;
  }
}
</style>