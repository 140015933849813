import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { category: String }

  initialize() {
    console.log('initialize connected')
    this.handlePatientSelected = this.handlePatientSelected.bind(this);
  }

  connect() {
    this.element.addEventListener("patient:selected", this.handlePatientSelected);
  }

  disconnect() {
    this.element.removeEventListener("patient:selected", this.handlePatientSelected);
  }

  handlePatientSelected(event) {
    const { patientId } = event.detail;
    this.showDemographics(patientId);
  }

  showDemographics(patientId) {
    const url = `/schedule_view/patients/${patientId}?turbo_frame_id=child-appointment-patient-demographics`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

}
