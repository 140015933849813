import { createGlobalStateFacade } from './plugins/types/global-state-facade.functions';

// Initializes application-announcements feature
import '@/features/application-announcements/initialize';
import '@/features/custom-form-templates/initialize';
import '@/features/patient-chart/features/video-link/initialize';
import '@/features/complex-queries/initialize';

export function initVue($: any) {
  ('use strict');
  $(function () {
    // @ts-ignore
    window.globalStateFacade = createGlobalStateFacade();
  });
}


