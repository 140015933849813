import http from '@/http/http';
import { PatientFormTemplateDetails } from '../types/patient-form-template-details';
import { PatientFormTemplatePaginatedListDto, PatientFormTemplatePaginatedList } from '../types/patient-form-template-list-item';
import { createPatientFormTemplateListItemFromDto } from '../types/patient-form-template-list-item.functions';

export function createFormTemplate(
  templateName: string
): Promise<PatientFormTemplateDetails> {
  return http
    .post<PatientFormTemplateDetails>('/patient_form_templates', 
      { name: templateName },
      { params: { format: 'json' }}
    )
    .then(res =>res.data);
}

export function fetchMyAuthoredFormTemplates(page: number): Promise<PatientFormTemplatePaginatedList> {
  return http
    .get<PatientFormTemplatePaginatedListDto>('/patient_form_templates',
      { 
        params: { 
          format: 'json',
          page: page
        }
      }
    )
    .then(res => ({
      isUserAdmin: res.data.is_user_admin,
      list: res.data.list.map(t => createPatientFormTemplateListItemFromDto(t)),
      pagination: res.data.pagination
    }))
}

export function fetchFormTemplateDetails(id: number): Promise<PatientFormTemplateDetails> {
  return http
  .get<PatientFormTemplateDetails>(`/patient_form_templates/${id}`,
    { params: { format: 'json' }}
  )
  .then(res => res.data);
}

export function updateFormTemplate(template: PatientFormTemplateDetails): Promise<PatientFormTemplateDetails> {
  return http
  .put<PatientFormTemplateDetails>(`/patient_form_templates/${template.id}`,
    { patient_form_template: template },
    { params: { format: 'json' }}
  )
  .then(res => res.data);
}