<template>
  <div class="base-label" :class="labelClasses">
    <div v-if="description" class="title">
      <base-typography formLabel>
        {{ description }}
      </base-typography>
    </div>
    <hr v-if="showLabelUnderline" />
    <div class="form-item-container">
      <slot />
      <slot name="button" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import BaseTypography from '../base-typography/base-typography.vue';

export default defineComponent({
  components: { BaseTypography },
  props: {
    description: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    showLabelUnderline: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const labelClasses = computed(() => ({
      inline: props.inline
    }))
    return {
      labelClasses
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/global-styles.scss';

.base-label {
  width: inherit;
  text-transform: capitalize;
  .form-item-container {
    display: flex;
    flex-grow: 1;
    * {
      flex-grow: 1;
    }
  }
  hr {
    margin-bottom: 0.5em;
    margin-top: 0em;
  }
  &.inline {
    display: flex;
    white-space: nowrap;
    background-color: $ava-background-color;
    border: 1px solid $ava-border-color;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      box-sizing: border-box;
      border-right: 1px solid $ava-border-color;
      padding: 0 1em;
    }
    input {
      border: 1px solid transparent;
    }
  }
}
</style>