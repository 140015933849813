import { TextSelection } from '@tiptap/pm/state'

export function replaceKeywordWithMacroText(editor) {
  if (editor.isFocused) {      
    const raw_macros = JSON.parse(localStorage.getItem('keyShortcuts'));
    Object.keys(raw_macros).forEach((keyword) => {
      // Select keyword in editor and replace with macro text
      if (selectKeywordMacroInEditor(keyword, editor)) {
        replaceSelectedTextInRichTextEditor(raw_macros[keyword], editor);
      }
    });
  }
}

export const dynamicMacroRegex = /<<.+?>>/;

export const replaceSelectedTextInRichTextEditor = (replacementText, editor) => {
  const { from, to } = editor.state.selection;
  let tr = editor.state.tr;
  if (from !== to) {
    tr = editor.state.tr.delete(from, to); // Delete the originally selected text
  }
  
  // Split the replacementText by newline characters
  const splitText = replacementText.split(/\r?\n/);

  // Initialize the insertion point
  let insertionPoint = from;

  splitText.forEach((text, index) => {
    if (index === 0) {
      if (text) {
        // Insert the first piece of text without creating a new paragraph
        const textNode = editor.schema.text(text);
        tr = tr.insert(insertionPoint, textNode);
        insertionPoint += textNode.nodeSize;
      }
    } else {
      // For subsequent pieces of text, insert a paragraph node
      const paragraphNode = text 
        ? editor.schema.nodes.paragraph.create(null, editor.schema.text(text)) 
        : editor.schema.nodes.paragraph.create();
      tr = tr.insert(insertionPoint, paragraphNode);
      insertionPoint += paragraphNode.nodeSize; // Update insertion point by the node size
    }
  });

  const selection = TextSelection.create(tr.doc, insertionPoint);
  tr = tr.setSelection(selection);

  // Apply the transaction
  editor.view.dispatch(tr);
}


// Selects substring in the editor except it's between << and >> delimiters
// returns true if selection is successful, false otherwise
const selectKeywordMacroInEditor = (substring, editor) => {
  let startPos = null;
  let endPos = null;

  editor.state.doc.descendants((node, pos) => {
    if (node.isText) {
      const searchIdx = findFirstSubstringOutsideDynamicMacros(node.text, substring);
      if (searchIdx !== -1) {
        startPos = pos + searchIdx;
        endPos = startPos + substring.length;
      }
    }
    return startPos === null; // Continue the traversal if the substring has not been found yet
  });

  if (startPos !== null && endPos !== null) {
    const newSelection = TextSelection.create(editor.state.doc, startPos, endPos);
    editor.view.dispatch(editor.state.tr.setSelection(newSelection));
    return true;
  }
  return false
};

function findFirstSubstringOutsideDynamicMacros(inputString, substring) {
  const pattern = new RegExp(`<<.+?>>|(${substring})`, 'g');
  let match;

  while ((match = pattern.exec(inputString)) !== null) {
    // Check if the match is the target substring and not within brackets
    if (match[1]) {
      return match.index; // Return the index of the first match
    }
  }

  return -1; // Return -1 if no match is found outside brackets
}

export const turnDynamicMacrosIntoNodes = (editor) => {
  let transaction = editor.state.tr;
  let hasReplacements = false;
  
  editor.state.doc.descendants((node, pos) => {
    if (node.isText && node.type.name !== 'dynamicMacroNode') {
      // Reverse the matches so that we can replace from the end of the document
      const matches = Array
        .from(node.text.matchAll(/<<.+?>>/g))
        .reverse() // Collect and reverse matches so that we can replace from the end of the document and not shift the positions
      matches.forEach((match) => {
        const from = pos + match.index;
        const to = from + match[0].length;
        const newNode = editor.schema.nodes.dynamicMacroNode.create({ dataMacroText: match[0] });
        try {
          transaction.replaceWith(from, to, newNode);
        } catch (error) {
          console.warn(`Error in turnDynamicMacrosIntoNodes method: ${error.message}`);
        }
        hasReplacements = true;
      });
    }
  });

  if (hasReplacements) {
    editor.view.dispatch(transaction);
  }

}

export const moveCursorToEnd = (tr, doc, dispatch) => {
  const pos = doc.content.size;
  const transaction = tr.setSelection(TextSelection.create(tr.doc, pos));
  dispatch(transaction);
}

export const retrieveDynamicMacroNodeNearCursor = (editor) => {
  const { selection } = editor.state;
  const { $head } = selection;

  // Check for a node after the cursor
  const after = $head.nodeAfter;
  if (after && after.type.name === 'dynamicMacroNode') {
    // The $head position is the beginning of the node after the cursor
    return { node: after, position: $head.pos };
  }
  return { node: null, position: null };
}