import { PatientFormTemplateListItem, PatientFormTemplateListItemDto } from './patient-form-template-list-item'


export const createPatientFormTemplateListItemFromDto = (
  dto: PatientFormTemplateListItemDto
): PatientFormTemplateListItem => {
  return {
    id: dto.id,
    name: dto.name,
    published: dto.globally_available,
    createdAt: dto.created_at
  }
}