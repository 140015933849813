window.initTranscriberButton = initTranscriberButton;

export function initTranscriber($) {

  window.addEventListener('DOMContentLoaded', (function(e) {
    initTranscriberButton();
  }), false);

}

function initTranscriberButton() {
  $(document).off('click', '#toggle-transcription-button');
  $(document).on('click', '#toggle-transcription-button', function() {
    $('#transcriber-container').slideToggle();
    $('#toggle-transcription-button').toggleClass('footer-button-active');
  });
  // Create right click for viewing historical scribes
  $(document).off('contextmenu', '#transcriber-button');
  $(document).on('contextmenu', '#transcriber-button', function(e) {
    $('#toggle-transcription-button').toggleClass('footer-button-active');
    $.ajax({
      url: '/transcriber/transcriptions',
      data: {
        patient_id: $(this).data('patient-id')
      },
      dataType: 'script'
    })
    e.preventDefault();
  });
  $(document).off('contextmenu', '#toggle-transcription-button');
  $(document).on('contextmenu', '#toggle-transcription-button', function(e) {
    $('#toggle-transcription-button').toggleClass('footer-button-active');
    $.ajax({
      url: '/transcriber/transcriptions',
      data: {
        patient_id: $(this).data('patient-id')
      },
      dataType: 'script'
    })
    e.preventDefault();
  });
}
