<template>
  <div class="form-options-manager">
    <base-typography capitalize caption> selection options: </base-typography>
    <base-typography v-if="errorMessage" warning>{{errorMessage}}</base-typography>
    <base-typography 
      v-if="!modelValue?.length" 
      capitalize 
      caption 
      italics
      class="ava-margin-v-sm"
    >
      submit a list of options items to select
    </base-typography>
    <div class="form-options-manager-list-item" :key="index" v-for="item, index in modelValue">
      <base-typography body>{{item.value}}</base-typography>
      <base-button-icon @click="deleteItem(item.value)">
        <i class="fa fa-times"/>
      </base-button-icon>
    </div>
    <div class="form-options-manager-new-value-container">
      <base-input 
        :placeholder="`option ${modelValue.length + 1}`" 
        @blur="submit"
        @keyup.enter="submit" 
        v-model="newValue" />
      <base-button-icon @click="submit"> 
        <i class="fa fa-arrow-right" />
      </base-button-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseInput from '@/ui-library/base-input/base-input.vue';
import { FormItemOptions } from '@/features/custom-form-templates/types/patient-form-template-details';
import { createFormItemOptions } from '@/features/custom-form-templates/types/patient-form-template-details.functions';

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  errorMessage: {
    type: String,
    required: false
  },
  modelValue: {
    default: () => [],
    type: Array as PropType<FormItemOptions[]>
  }
})

const deleteItem = (value: string) => {
  emit('update:modelValue', props.modelValue.filter((o) => o.value !== value));
}

const newValue = ref('');
const submit = () => {
  if (newValue.value) {
    emit('update:modelValue', [...props.modelValue, createFormItemOptions(newValue.value)])
    newValue.value = ''
  }
}

</script>

<style lang="scss" scoped>
.form-options-manager {
  .form-options-manager-list-item,
  .form-options-manager-new-value-container {
    padding-left: 2em;
  }
  .form-options-manager-list-item {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .form-options-manager-new-value-container {
    display: flex;
    gap: 1em;
  }
}
</style>