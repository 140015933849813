<template>
  <div v-show="flashText" class="form-editor-saving-message-text-container">
    <div class="form-editor-saving-message-text-pill">
      <base-typography :warning="flashType === 'warning'">
        {{ flashText }}
      </base-typography>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';

const props = defineProps({
  flashText: {
    type: String
  },
  flashType: {
    type: String
  }
})

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';

.form-editor-saving-message-text-container {
  position: fixed;
  bottom: 1em;
  .form-editor-saving-message-text-pill {
    padding: 0.5em 0.7em;
    border-radius: 8px;
    border: 1px solid $ava-border-color;
    background-color: $ava-background-color;
  }
}
</style>