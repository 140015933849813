import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    createdAtEpoch: Number,
    patientId: Number,
    clinicId: Number,
    walkInId: Number,
    notes: String,
  }

  connect() {
    this.updateElapsedTime(this.calculateElapsedTime());
    // Start the timer when the component connects
    this.startTimer();
    this.makeDraggable();
    this.element.addEventListener("contextmenu", this.handleRightClick.bind(this));
    this.element.addEventListener("dblclick", this.handleDoubleClick.bind(this));
    this.element.addEventListener("click", this.handleSingleClick.bind(this));
    $(this.element).tooltipster({
			theme: 'tooltipster-noir',
			contentAsHTML: true,
    })
  }

  disconnect() {
    // Clear the timer when the component disconnects
    this.stopTimer();
  }

  startTimer() {
    // Store the interval ID so it can be cleared later
    this.intervalId = setInterval(() => {
      this.updateElapsedTime(this.calculateElapsedTime());
    }, 20000); // Update every 20 seconds
  }

  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateElapsedTime(minutes) {
    // Find the span element(s) within the controller's scope and update its content
    const timerElements = this.element.querySelectorAll(".walk-in-timer");
    timerElements.forEach(el => {
      el.textContent = `${minutes}`;
    });
  }

  calculateElapsedTime() {
    const now = new Date().getTime(); // Current time in milliseconds since epoch
    const elapsedTime = now - this.createdAtEpochValue;
    const minutesElapsed = Math.floor(elapsedTime / 60000); // Convert milliseconds to minutes

    return minutesElapsed;
  }

  handleRightClick(event) {
    if (this.patientIdValue) {
      window.location.href = `/patients/${this.patientIdValue}`;
      event.preventDefault(); // Prevent the normal context menu
    }
  }

  handleDoubleClick(event) {
    $.ajax({
      url: `/schedule_view/clinics/${this.clinicIdValue}/walk_ins/${this.walkInIdValue}/edit`,
      type: 'GET',
      dataType: 'script',
    });
  }

  handleSingleClick(event) {
    if (!this.patientIdValue) return;

    $.ajax({
      url: `/patient_sidebar/patients/${this.patientIdValue}`,
      type: 'GET',
      dataType: 'script',
    });
  }

  makeDraggable() {
    // Ensure FullCalendar and its Draggable class are available
    if (window.FullCalendar && window.FullCalendar.Draggable) {
      new window.FullCalendar.Draggable(this.element, {
        eventData: {
          duration: '00:15',
          extendedProps: {
            category: 'walkIn',
            walk_in_id: this.walkInIdValue,
            patient_id: this.patientIdValue,
            notes: this.notesValue
          }
        }
      });
    } else {
      console.error("FullCalendar.Draggable is not available");
    }
  }
}
