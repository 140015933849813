<template>
  <div v-if="liteFormItems.length" class="form-editor-conditional-section-container">
    <base-checkbox 
      label="Only show question if meets conditions" 
      @click="clickWarning" 
      v-model="showConditionalForm" 
    />
    <template v-if="showConditionalForm">
      <conditional-rule-manager v-model:rules="rules" :liteFormItems="liteFormItems">
        <template #rule-combination-type>
          <base-select 
            v-model="ruleCombinationType"
            :label="`Multiple rules are evaluated together using ${ruleCombinationType} operator`" 
          >
            <option v-for="i in ['OR', 'AND']" :key="i"> {{ i }}</option>
          </base-select>
        </template>
      </conditional-rule-manager>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import BaseCheckbox from '@/ui-library/base-checkbox/base-checkbox.vue';
import { createDefaultConditionalFormDetail } from '../../../../../../../../types/patient-form-template-details.functions';
import { FlattenedLiteFormItem } from '../../../../../../../../types/patient-form-lite-form-item';
import ConditionalRuleManager from './conditional-rule-manager.vue';
import BaseSelect from '@/ui-library/base-select/base-select.vue';
import { useModelWrapper } from '@/composition/use-model-wrapper';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: [Boolean, Object],
    required: true,
    default: () => true
  },
  liteFormItems: {
    required: true,
    type: Object as PropType<FlattenedLiteFormItem[]>,
    default: () => []
  }
})

const conditionalFormDetail = useModelWrapper(props, emit, 'modelValue');


const clickWarning = (event: Event) => {
  if (showConditionalForm.value &&
    rules.value.length &&
    !confirm('Are you sure? Unticking this box will delete your rules forever') 
  ) {
    event.preventDefault()
  }
}

const showConditionalForm = computed({
  // @ts-ignore
  get: () => props.modelValue?.id ? true : false,
  set: (value) => {
    emit('update:modelValue', value === true ? createDefaultConditionalFormDetail() : true)
  }
})

const ruleCombinationType = computed({
  // @ts-ignore
  get: () => props.modelValue?.condition || 'AND',
  set: (value) => {
    if (conditionalFormDetail.value.id) {
      emit('update:modelValue', {...conditionalFormDetail.value, condition: value})
    }
  }
})

const rules = computed({
  // @ts-ignore
  get: () => props.modelValue?.rules || [],
  set: (value) => {
    if (conditionalFormDetail.value.id) {
      emit('update:modelValue', {...conditionalFormDetail.value, rules: value})
    }
  }
})

</script>

<style lang="scss" scoped>
.form-editor-conditional-section-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
</style>