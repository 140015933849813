export const generatePlainTextTextareaSelector = () => 'form.user_macros textarea, form.user_macros .note-editable';

export const setConjunctionCharacter = (string) => {
	let output = ''
	if (string.includes('&')) {
		output = '&'
	} else if (string.includes('#')) {
		output = '#'
	} else if (string.includes('labs:')) {
		output = 'labs:'
	} else if (string.includes('medications:')) {
		output = 'medications:'
	} else if (string.includes('vitals:')) {
		output = 'vitals:'
	} else {
		output = '&'
	}
	return output
}

export const setConjunctionWord = (string) => {
	let output = ''
	if (string == '&') {
		output = 'and'
	} else if (string == '#') {
		output = 'or'
	} else {
		output = 'and'
	}
	return output
}

export const setRemoteUrl = (string) => {
	let output = ''
	if (string.includes('labs:')) {
		output = '/macros/labs'
	}	else if (string.includes('medications:')) {
		output = '/macros/medications'
	}	else if (string.includes('vitals:')) {
		output = '/macros/vitals'
	} else {
		output = null
	}
	return output
}
