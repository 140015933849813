export function decodeHtmlEntities(encodedString) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}

export function containsHtml(text: string) {
  return /<\/?[a-z][\s\S]*>/i.test(text)
}

export function randomHex() {
  return Math.random().toString(16).substring(2);
}