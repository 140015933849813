import http from '@/http/http';
import { MyClinicFormTemplatesDto } from '../types/my-clinic-form-templates-dto';
import { MyClinicFormTemplatesState } from '../types/my-clinic-form-templates-state';

const defaultParams = {
  format: 'json'
}

export function fetchMyClinicFormTemplates(): Promise<Partial<MyClinicFormTemplatesState>> {
  return http
    .get<MyClinicFormTemplatesDto>('/my_clinic_form_templates', { params: defaultParams })
    .then(res => ({
        clinicId: res.data.clinic_id,
        clinicName: res.data.clinic_name,
        clinicFormIds: res.data.clinic_form_ids
      })
    )
}

export function addClinicFormTemplate(id: number): Promise<Partial<MyClinicFormTemplatesState>> {
  return http
    .post<MyClinicFormTemplatesDto>(`/my_clinic_form_templates`, 
      { patient_form_template_id: id },
      { params: defaultParams }
    )
    .then(res => ({
        clinicId: res.data.clinic_id,
        clinicName: res.data.clinic_name,
        clinicFormIds: res.data.clinic_form_ids
      })
    )
}
export function deleteClinicFormTemplate(id: number): Promise<Partial<MyClinicFormTemplatesState>> {
  return http
    .delete<MyClinicFormTemplatesDto>(`/my_clinic_form_templates/${id}`, { params: defaultParams })
    .then(res => ({
        clinicId: res.data.clinic_id,
        clinicName: res.data.clinic_name,
        clinicFormIds: res.data.clinic_form_ids
      })
    )
}