import { Controller } from "@hotwired/stimulus";
import { toggleDisabled, toggleDisplay } from "../../utilities/dom_helpers";

export default class extends Controller {

  static targets = [ 
    "fullStartingListOptionsSelect", 
    "employeeStartingListOptionsSelect", 
    "startingListSelect" 
  ]

  connect() {
    /**
     *  We want to show a different select (fullStartingListOptionsSelectTarget or employeeStartingListOptionsSelectTarget)
     *  based if startingListSelect was selecting a Clinic or Employee.
     *  data-option-type is set in ./component.rb. It refers to the klass of object that is selected in StartingListSelectTarget
     */
    const selectedOption = this.startingListSelectTarget.options[this.startingListSelectTarget.selectedIndex];
    const optionType = selectedOption.getAttribute("data-option-type");
    this.toggleStartingListOptions(optionType)
  }

  toggleStartingListOptions(selectedOptionType) {
    if (selectedOptionType == 'Employee') {
      this.toggleShow(this.employeeStartingListOptionsSelectTarget, true);
      this.toggleShow(this.fullStartingListOptionsSelectTarget, false);
    } else {
      this.toggleShow(this.employeeStartingListOptionsSelectTarget, false);
      this.toggleShow(this.fullStartingListOptionsSelectTarget, true);
    }
  }

  toggleShow(el, status) {
    toggleDisabled(el, !status);
    toggleDisplay(el, status);
  }

  handleSelectChange(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const optionType = selectedOption.getAttribute("data-option-type");
    this.toggleStartingListOptions(optionType)
    
  }
}