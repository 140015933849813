import { Controller } from "@hotwired/stimulus";
import { consumer } from "../../../javascript/channels/consumer";
import { parse } from "best-effort-json-parser";
import DOMPurify from 'dompurify';

export default class extends Controller {
  static values = { nonce: String };

  connect() {
    this.outputElement = document.querySelector(`[data-llm-nonce="${this.nonceValue}"]`);

    this.subscription = consumer.subscriptions.create(
      { channel: "EmrChannels::LlmChannel", nonce: this.nonceValue },
      {
        received: this.handleReceived.bind(this)
      }
    )
  }

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription)
    }
  }

  handleReceived(data) {
    // Handle the regular display update
    const strategy = displayStrategies[data.display_strategy || 'default']
    strategy(data.aggregate_response, this.outputElement)

    // Check for completion status
    if (data.status === 'completed') {
      this.handleCompletion(data)
    }
  }

  handleCompletion(data) {
    // If a target controller and action are specified, dispatch to it
    if (this.hasOnCompleteTargetValue && this.hasOnCompleteActionValue) {
      const targetElement = document.querySelector(this.onCompleteTargetValue)
      if (targetElement) {
        const event = new CustomEvent('llm:complete', { 
          detail: data,
          bubbles: true,
          cancelable: true 
        })
        targetElement.dispatchEvent(event)
      }
    }

    // Dispatch a general completion event that any parent can listen to
    const completionEvent = new CustomEvent('asyncLlm:complete', {
      detail: data,
      bubbles: true,
      cancelable: true
    })
    this.element.dispatchEvent(completionEvent)
  }
}

const displayStrategies = {
  default: (data, element) => { 
    if (element.tagName === 'TEXTAREA') {
      element.value = data;
      element.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
    } else {
      if (typeof data === 'string') {
        const sanitizedHtml = DOMPurify.sanitize(data, {
          ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'ul', 'ol', 'li', 'br', 'div'],
          ALLOWED_ATTR: ['class']
        });
        element.innerHTML = sanitizedHtml;
      } else {
        console.error('Expected data to be a string, but received:', typeof data);
      }
    }
   },
  textarea: (data, element) => { 
    element.value = data;
    element.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
  },
  html: (data, element) => { 
    if (typeof data === 'string') {
      const sanitizedHtml = DOMPurify.sanitize(data, {
        ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'ul', 'ol', 'li', 'br', 'div'],
        ALLOWED_ATTR: ['class']
      });
      element.innerHTML = sanitizedHtml;
    } else {
      console.error('Expected data to be a string, but received:', typeof data);
    }
  },
  jsonFormatted: (data, element) => {
    const parsedData = parse(data);
    if (typeof parsedData === 'object' && parsedData !== null) {
      const formattedHtml = Object.entries(parsedData).map(([key, value]) => `
        <strong>${escapeHtml(key)}:</strong><br />
        ${formatValue(value)}<br /><br />
      `).join('');

      element.innerHTML = formattedHtml;
    } else {
      // Don't update the element since we don't want the element to look like it is flickering
    }
  },
};

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function formatValue(value) {
  if (typeof value === 'string') {
    return escapeHtml(value).replace(/\n/g, '<br>');
  } else if (Array.isArray(value)) {
    return value.map(item => formatValue(item)).join('<br>');
  } else if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .map(([k, v]) => `<b>${escapeHtml(k)}:</b> ${formatValue(v)}`)
      .join('<br>');
  } else {
    return '';
  }
}
