import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submenu"]

  connect() {
    // Initialization code, if needed
  }

  showSubmenu(event) {
    const submenuContainer = event.currentTarget
    const submenu = submenuContainer.querySelector('.custom-context-menu-options')
    if (submenu) {
      submenu.style.display = 'block'
      this.positionSubmenu(submenu, submenuContainer)
    }
  }

  hideSubmenu(event) {
    const submenuContainer = event.currentTarget
    const submenu = submenuContainer.querySelector('.custom-context-menu-options')
    if (submenu) {
      submenu.style.display = 'none'
    }
  }

  positionSubmenu(submenu, submenuContainer) {
    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    }
    
    const submenuRect = submenu.getBoundingClientRect()
    const containerRect = submenuContainer.getBoundingClientRect()

    let left = containerRect.right
    let top = containerRect.top

    if (left + submenuRect.width > viewport.width) {
      left = containerRect.left - submenuRect.width
    } else {
      left = containerRect.right - 20;
    }

    if (top + submenuRect.height > viewport.height) {
      const overflow = top + submenuRect.height - viewport.height
      top = Math.max(0, top - overflow)
    }

    submenu.style.position = 'fixed'
    submenu.style.left = `${left}px`
    submenu.style.top = `${top}px`
  }
}