<template>
  <div class="form-editor-card-container">
    <div tabindex="0" 
      @keypress.enter="toggleDetails" 
      @click="toggleDetails" 
      class="form-editor-card-header-container">
      <slot name="header" :toggleDetails="toggleDetails" :isDetailsOpen="isDetailsOpen">
        <form-editor-card-header 
          :title="title" 
          :isDetailsOpen="isDetailsOpen"
          @delete="$emit('delete')">
          <template #pre-title>
            <slot />
          </template>
        </form-editor-card-header>
      </slot>
    </div>
    <base-transition-expand>
      <div v-if="isDetailsOpen" class="form-editor-card-body">
        <slot name="body" :closeDetails="closeDetails" :isDetailsOpen="isDetailsOpen" />
      </div>
    </base-transition-expand>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BaseTransitionExpand from '@/ui-library/base-transition/base-transition-expand.vue';
import FormEditorCardHeader from '../../../components/form-editor-card-header.vue';

defineEmits(['delete']);

const props = defineProps({
  title: {
    type: String,
    required: false
  },
  openByDefault: {
    type: Boolean,
    default: false
  }
});

const isDetailsOpen = ref(props.openByDefault);
const toggleDetails = () => isDetailsOpen.value = !isDetailsOpen.value;
const closeDetails = () => isDetailsOpen.value = false;

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.form-editor-card-container {
  border: 1px solid $ava-border-color;
  border-radius: 0.2em;
  background-color: white;
  .form-editor-card-body {
    padding: 1em;
  }
}
</style>