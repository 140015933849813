import { PropType } from 'vue';
import { FlattenedLiteFormItem } from '../../../../../../../types/patient-form-lite-form-item';
import { FormTemplateFormItem } from '../../../../../../../types/patient-form-template-details';

export const formItemComponentDefaultPropDefinition = () => (
  {
    item: {
      required: true,
      type: Object as PropType<FormTemplateFormItem>
    },
    autoSave: {
      type: Boolean,
      default: true
    },
    liteFormItems: {
      required: true,
      type: Object as PropType<FlattenedLiteFormItem[]>,
      default: () => []
    }
  }
)