<template>
  <custom-form-template-layout v-if="getFormTemplate" @showCreateMenu="closeFormTemplateView">
    <template #edit-form>
      <edit-form-template />
    </template>
    <template #preview-form>
      <preview-form-template :formTemplate="getFormTemplate" />
    </template>
  </custom-form-template-layout>
  <base-loader :isLoading="getIsLoading" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePatientFormTemplateStore } from '../../store/custom-patient-forms-store';
import CustomFormTemplateLayout from './layouts/custom-form-template-layout.vue';
import EditFormTemplate from './features/edit-form-template/edit-form-template.vue';
import PreviewFormTemplate from './features/preview-form-template/preview-form-template.vue';
import BaseLoader from '@/ui-library/base-loader/base-loader.vue';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const store = usePatientFormTemplateStore();
const {
  getFormTemplate,
  getIsLoading
} = storeToRefs(store);

const router = useRouter();
const route = useRoute();

const closeFormTemplateView = () => router.push({ name: 'patient-form-templates-new' });

onMounted(() => {
  store.initCustomFormTemplate(parseInt(route.params.id as string));
})

</script>

<style lang="scss" scoped></style>