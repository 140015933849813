<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    headline: {
      type: Boolean,
      required: false
    },
    title: {
      type: Boolean,
      required: false
    },
    formLabel: {
      type: Boolean,
      required: false
    },
    body: {
      type: Boolean,
      required: false
    },
    caption: {
      type: Boolean,
      required: false
    },
    bold: {
      type: Boolean,
      required: false
    },
    italics: {
      type: Boolean,
      required: false
    },
    capitalize: {
      type: Boolean,
      required: false
    },
    warning: {
      type: Boolean,
      required: false
    },
  },
  setup(props) {
    const classes = computed(() => ({
      headline: props.headline,
      title: props.title,
      body: props.body,
      caption: props.caption,
      bold: props.bold,
      capitalize: props.capitalize,
      'form-label': props.formLabel,
      warning: props.warning,
      italics: props.italics
    }));
    return {
      classes
    };
  }
});
</script>

<style lang="scss" scoped>

.headline {
  font-size: 24px;
  text-transform: capitalize;
}

.title {
  font-size: 16px;
  text-transform: capitalize;
}

.body {
  font-size: 14px;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  white-space: pre-wrap;
}

.caption {
  font-size: 12px;
  color: #767e8c;
}

.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.form-label {
  font-size: 0.75rem;
  font-weight: 300;
  margin: 0;
}

.warning {
  color: #931F1F;
}
.italics {
  font-style: italic;
}
</style>
