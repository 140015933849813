import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['row'];
  navigate(event) {
    event.preventDefault();
    const url = this.rowTarget.getAttribute('data-href');
    const remote = this.rowTarget.getAttribute('data-remote') === 'true';
    const type = this.rowTarget.getAttribute('data-type') || 'GET';
    
    if (url) {
      if (remote) {
        $.ajax({
          type: type.toUpperCase(),
          url: url,
          dataType: 'script'
        });
      } else {
        window.location.href = url;
      }
    }
  }
}