import subscribeToGenerateTextChannel from "channels/emr_channels/generate_text_channel";

class TextGenerator {
  init(jqObject) {
    initTextGeneratorButton(jqObject);
    return;
  }
}

function initTextGeneratorButton(jqObject) {
  jqObject.off('click', '.text-generator-button');
  jqObject.on('click', '.text-generator-button', function() {
    let url = $(this).data('url');
    let patientId = $(this).data('patient-id');
    let nonce = jqObject.find('[data-nonce]').data('nonce');
    let name = jqObject.find('.text-generator-name').val();
    let currentText = jqObject.find('.text-generator-target').val();
    let textGeneratorCategory = $(this).data('category');

    if (!!nonce) {
      // Gives us protection in case nonce is not present
      // Otherwise all channels with a null nonce would be broadcasting to each other
      $.ajax({
        url: url,
        type: 'GET',
        data: {
          patient_id: patientId,
          nonce: nonce,
          category: textGeneratorCategory,
          name: name,
          current_text: currentText
        },
        dataType: 'script',
        success: function() {
          subscribeToGenerateTextChannel(nonce);
        }
      })
    }
  });
}

export default new TextGenerator();