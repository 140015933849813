import { Controller as BaseController } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";

export class Controller extends BaseController {
  static targets = [
    "consults",
    "consultButtons",
    "labs", 
    "labButtons",
    "investigations",
    "investigationButtons",
    "letters",
    "letterButtons",
    "medications",
    "medicationButtons",
    "loadingSkeleton",
    "loadingMessage",
    "loadingIcon",
    "assistEntitiesContainer"
  ];

  static values = {
    nonce: String
  };

  loadingMessages = [
    "Thinking...",
    "Fetching the tricorder...",
    "Paging the robots...",
    "Consulting digital MD degree...",
    "Consulting the medical droid...",
    "Consulting the Prime Radiant...",
    "Unfolding data in all dimensions...",
    "Traversing the dark forest...",
    "Winding up the analytical clockwork..."
  ];

  connect() {
    this.subscription = null;
    this.buttonsAdded = false;

    this.subscribeToChannel();
    // Start the loading message rotation
    this.startLoadingMessageRotation();
    
    this.clearTimeouts();
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // Clear any pending timers
    if (this.messageRotationInterval) {
      clearInterval(this.messageRotationInterval);
    }
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
  }

  subscribeToChannel() {
    const consumer = createConsumer();
    
    this.subscription = consumer.subscriptions.create(
      { 
        channel: "EmrChannels::AssistActionItemsChannel",
        nonce: this.nonceValue 
      },
      {
        received: this.handleMessage.bind(this)
      }
    );
  }

  handleMessage(data) {
    // Assuming data has structure: { category: string, html: string }
    const { category, html, status } = data;

    // Check for done status
    if (status === 'done') {
      // Clear any existing message rotation
      if (this.messageRotationTimeout) {
        clearTimeout(this.messageRotationTimeout);
      }

      if (this.loadingIconTarget) {
        const spinner = this.loadingIconTarget.querySelector('[data-loading-state]');
        if (spinner) {
          spinner.dataset.loadingState = 'completed';
          this.loadingIconTarget.classList.add('loading-logo--completed');
        }
      }

      // Show completion message if we still have the target
      if (this.hasLoadingMessageTarget) {
        const finalMessage = this.buttonsAdded ? 'Action Items' : 'No Action Items';
        this.loadingMessageTarget.classList.remove('italic');
        this.loadingMessageTarget.classList.add('bold');
        this.loadingMessageTarget.textContent = finalMessage;
      }
      return;
    }

    // Map category to corresponding target names
    const targetMapping = {
      consult: ['consults', 'consultButtons'],
      lab: ['labs', 'labButtons'],
      investigation: ['investigations', 'investigationButtons'],
      letter: ['letters', 'letterButtons'],
      medication: ['medications', 'medicationButtons']
    };

    const [containerTarget, buttonContainerTarget] = targetMapping[category] || [];

    if (!containerTarget || !buttonContainerTarget) {
      console.warn(`Unknown category received: ${category}`);
      return;
    }

    // Show the container if we have entities
    this.assistEntitiesContainerTarget.classList.remove('hidden-field');
    this[`${containerTarget}Target`].classList.remove('hidden-field');
    this.buttonsAdded = true;

    this.handleHTMLEntities(html, buttonContainerTarget);
  }

  handleHTMLEntities(html, targetName) {
    const container = this[`${targetName}Target`];
    if (container) {
      container.insertAdjacentHTML('beforeend', html);
    }
  }

  createEntityButton(entity) {
    const button = document.createElement('button');
    button.className = 'ava-button ava-button--primary ava-m-1';
    button.textContent = entity.label;

    // Add data attributes for any additional entity data
    Object.entries(entity).forEach(([key, value]) => {
      if (key !== 'label') {
        button.dataset[key] = value;
      }
    });

    // Add click handler
    button.addEventListener('click', () => this.handleEntityButtonClick(entity));

    return button;
  }

  handleEntityButtonClick(entity) {
    // Implement click handling logic here
    // You might want to dispatch a custom event or make an API call
    const event = new CustomEvent('entity-selected', {
      detail: entity,
      bubbles: true
    });
    this.element.dispatchEvent(event);
  }

  startLoadingMessageRotation() {
    // Initial random delay between 2-5 seconds
    const getRandomDelay = () => Math.random() * (4000 - 2000) + 250;
    
    const rotateMessage = () => {
      if (this.loadingMessageTarget) {
        const currentMessage = this.loadingMessageTarget.textContent;
        let newMessage;
        
        // Ensure we don't repeat the same message
        do {
          newMessage = this.loadingMessages[Math.floor(Math.random() * this.loadingMessages.length)];
        } while (newMessage === currentMessage);
        
        this.loadingMessageTarget.textContent = newMessage;
      }
    };

    // Set up recurring rotation with random intervals
    const scheduleNextRotation = () => {
      this.messageRotationTimeout = setTimeout(() => {
        rotateMessage();
        scheduleNextRotation();
      }, getRandomDelay());
    };

    // Start the rotation
    scheduleNextRotation();
  }

  removeLoadingSkeleton() {
    if (this.loadingTimeout) {
      clearTimeout(this.loadingTimeout);
    }
    // Clear any pending message rotation timers
    if (this.messageRotationTimeout) {
      clearTimeout(this.messageRotationTimeout);
    }
  }

}
