import {Controller} from "@hotwired/stimulus";
import $ from 'jquery';

export default class extends Controller {
    static values = {
        success: String,
        warning: String
    }

    connect() {
        if (this.data.get('warningMessage') === 'true') {
            this.displayWarningMessage();
        }
        else {
            this.element.addEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this));
        }
    }

    disconnect() {
        this.element.removeEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this));
    }

    handleSubmitEnd(event) {
        let response = event.detail;

        if (response.success) {
            let element = document.getElementById('toast-success');
            this.displayMessage(element, this.successValue);
        } else {
            let element = document.getElementById('toast-error');
            this.displayMessage(element); // error html handled by .turbo_stream.erb file
        }
    }

    displayMessage(elementId, message, length = 2000) {
        const jqueryElement = $(elementId);
        jqueryElement.html(message);
        jqueryElement.fadeIn(400).delay(length).fadeOut(400);
    }

    displayWarningMessage() {
        let element = document.getElementById('toast-warning');
        this.displayMessage(element, this.warningValue, 10000);
   }
}