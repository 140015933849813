// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component-name (ie. shared-ui--checkmark-icon) in the component class.

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["toggeable", "ongoingButton", "archivedButton"]

  connect() {

  }

  toggle(event) {
    if (event.target === this.ongoingButtonTarget) {
      this.activateTarget(this.ongoingButtonTarget)
      this.deactivateTarget(this.archivedButtonTarget)
    } else if (event.target === this.archivedButtonTarget) {
      this.activateTarget(this.archivedButtonTarget)
      this.deactivateTarget(this.ongoingButtonTarget)
    }
  }

  activateTarget(target) {
    target.classList.add("active");
    target.classList.remove("inactive");
  }

  deactivateTarget(target) {
    target.classList.add("inactive");
    target.classList.remove("active");
  }
  
}