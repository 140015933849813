import { consumer, isSubscribed } from "../consumer"

function subscribeToWalkIns(clinicId) {
  const channelName = getChannelName(clinicId);
  const subscriptionObject = { channel: "EmrChannels::WalkInsChannel", channel_name: channelName };

  if (isSubscribed(subscriptionObject)) {
    return;
  } else if (!clinicId) {
    console.error("No clinicId provided to subscribeToWalkIns");
    return;
  } else {
    consumer.subscriptions.create(
      subscriptionObject,
      {
        connected() {
          console.log("Connected to WalkInsChannel:", channelName);
        },
  
        disconnected() {
          console.log("Disconnected from WalkInsChannel:", channelName);
        },
  
        received(data) {
          // Handle received data here
          console.log("Received walkIns data:", data);
          if (data.action === "create") {
            createWalkIn(data);
          } else if (data.action === "update") {
            updateWalkIn(data);
          } else if (data.action === "destroy") {
            deleteWalkIn(data);
          }
        },
      }
    );
  }
}

function createWalkIn(data) {
  const walkInData = data.walkIn;
  const walkInsContainer = document.getElementById("walk-in-queue-container");
  walkInsContainer.insertAdjacentHTML("beforeend", walkInData.html);
  updateCount()
}

function updateWalkIn(data) {
  const walkInData = data.walkIn;
  const walkIns = document.querySelectorAll(`#walk-in-queue-container [data-walk-in-id="${walkInData.id}"]`);
  walkIns.forEach(el => el.outerHTML = walkInData.html);
}

function deleteWalkIn(data) {
  const walkInData = data.walkIn;
  const walkIns = document.querySelectorAll(`#walk-in-queue-container [data-walk-in-id="${walkInData.id}"]`);
  walkIns.forEach(el => el.remove());
  updateCount()
}

function getChannelName(clinicId) {
  return `walk_ins_${clinicId}`;
}

function updateCount() {
  const counter = document.getElementById('walk-in-counter');
  const walkIns = document.querySelectorAll('#walk-in-queue-container .walk-in-object');
  counter.innerHTML = walkIns.length;
}

export default subscribeToWalkIns;
