import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { roomId: Number, roomSgid: String };
  static targets = [
    "roomedAppointments", "titleContainer", "appointmentSearch",
    "appointmentSearchField", "backgroundOverlay", "roomWidget",
    "roomableAppointmentsIndex"
  ];

  connect() {
    subscribeToRoom(this.roomIdValue, this.roomSgidValue);

    // Set Observer for the appointments index
    this.observer = new MutationObserver(mutations => {
      this.handleMutations(mutations);
    });
    this.checkForEmptyRoom();
    this.observer.observe(this.roomedAppointmentsTarget, { childList: true });
    this.setupSearch();
  }

  disconnect() {
    this.observer.disconnect();
  }

  handleMutations(mutations) {
    mutations.forEach(mutation => {
      if (mutation.type === 'childList') {
        this.checkForEmptyRoom();
      }
    });
  }

  checkForEmptyRoom() {
    const isEmpty = this.roomedAppointmentsTarget.querySelectorAll('.roomed-appointment').length === 0;
    if (isEmpty && !this.roomedAppointmentsTarget.querySelector('.no-appointments')) {
      this.roomedAppointmentsTarget.insertAdjacentHTML('beforeend', "<p class='no-appointments'>Room is Empty</p>");
      this.titleContainerTarget.classList.add('ava-accent-background');
      this.titleContainerTarget.classList.remove('ava-secondary-background');
    } else if (!isEmpty) {
      const emptyMessage = this.roomedAppointmentsTarget.querySelector('.no-appointments');
      if (emptyMessage) {
        emptyMessage.remove();
      }
      this.titleContainerTarget.classList.remove('ava-accent-background');
      this.titleContainerTarget.classList.add('ava-secondary-background');
    }
  }

  toggleAppointmentSearch(event) {
    // Refresh Appointments if the Search is Hidden
    if (this.appointmentSearchTarget.classList.contains('hidden-field')) {
      this.fetchRoomableAppointments();
      this.roomWidgetTarget.classList.add('active');
      this.backgroundOverlayTarget.style.display = 'block';
    } else {
      this.backgroundOverlayTarget.style.display = 'none';
      this.roomWidgetTarget.classList.remove('active');
    }
    this.appointmentSearchTarget.classList.toggle('hidden-field');
    this.appointmentSearchFieldTarget.focus();
  }

  fetchRoomableAppointments() {
    fetch('/schedule_view/roomable_appointments', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      this.renderAppointments(data.appointments);
    })
    .catch(error => console.error('Error fetching roomable appointments:', error));
  }

  renderAppointments(appointments) {
    const container = this.roomableAppointmentsIndexTarget;
    container.innerHTML = '';
  
    appointments.forEach(appointment => {
      // Create the outer flex container
      const flexDiv = document.createElement('div');
      flexDiv.className = 'ava-flex roomable-appointment row-link bottom-border';
      flexDiv.dataset.sgid = appointment.sgid
      flexDiv.dataset.searchTerms = `${appointment.patient_name.toLowerCase()} ${appointment.employee_name.toLowerCase()}`;
  
      // Create and append the patient name column
      const patientNameDiv = document.createElement('div');
      patientNameDiv.className = 'ava-col-9';
      const patientNameStrong = document.createElement('strong');
      patientNameStrong.textContent = appointment.patient_name;
      patientNameDiv.appendChild(patientNameStrong);
      flexDiv.appendChild(patientNameDiv);
  
      // Create and append the start time column
      const startTimeDiv = document.createElement('div');
      startTimeDiv.className = 'ava-col-3';
      startTimeDiv.textContent = appointment.start_time;
      flexDiv.appendChild(startTimeDiv);
  
      // Create and append the employee name column
      const employeeNameDiv = document.createElement('div');
      employeeNameDiv.className = 'ava-col-9';
      employeeNameDiv.textContent = appointment.employee_name;
      flexDiv.appendChild(employeeNameDiv);
  
      // Create and append the appointment type name column
      const appointmentTypeNameDiv = document.createElement('div');
      appointmentTypeNameDiv.className = 'ava-col-3';
      appointmentTypeNameDiv.textContent = appointment.appointment_type_name;
      flexDiv.appendChild(appointmentTypeNameDiv);

      // Add a click event listener to each appointment div
      flexDiv.addEventListener('click', () => {
        sendPatchRequest(appointment.sgid, this.roomIdValue);
        this.toggleAppointmentSearch();
      });
  
      // Append the complete appointment item to the container
      container.appendChild(flexDiv);
    });
  }  

  setupSearch() {
    const searchField = this.appointmentSearchFieldTarget;
    searchField.addEventListener('input', () => {
      const searchTerm = searchField.value.toLowerCase();
      const appointments = this.roomableAppointmentsIndexTarget.querySelectorAll('.roomable-appointment');
      appointments.forEach(appointment => {
        const terms = appointment.dataset.searchTerms; // Accessing data attribute
        appointment.style.display = terms.includes(searchTerm) ? '' : 'none';
      });
    });
  }

}

function subscribeToRoom(roomId, roomSgid) {
  const event = new CustomEvent('subscribeToRoom', { detail: { roomId: roomId, roomSgid: roomSgid } });
  document.dispatchEvent(event);
}

function sendPatchRequest(appointmentId, roomId) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const formData = new FormData();
  formData.append('appointment[room_id]', roomId);
  formData.append('appointment[status]', 'Roomed');

  fetch(`/schedule_view/appointments/${appointmentId}`, {
    method: 'PATCH',
    body: formData,
    headers: {
      'X-CSRF-Token': csrfToken
    },
  })
  .catch(error => console.error('Error sending PATCH request:', error));
}
