export const defaultOptions = {
  type: 'HTML',
  autofocus: true,
  editable: true,
  injectCSS: false,
  hideHTMLTags: false,
  options: {
    parseOptions: {
      preserveWhitespace: 'full'
    }
  },
}
