import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    canEditInvoice: Boolean
  }
    
  connect() {
    if (!this.canEditInvoiceValue) {
      const inputElements = document.querySelectorAll("#invoices-form-fields input");
      inputElements.forEach((input) => {  input.disabled = true; })
      
      const selectElements = document.querySelectorAll("#invoices-form-fields select");
      selectElements.forEach((select) => {  select.disabled = true; })
    }
  }

}