<template>
  <base-tooltip v-if="tooltip" :description="tooltip">
    <button :class="classes" class="base-button" v-bind="$attrs">
      <slot />
    </button>
  </base-tooltip>
  <button v-else :class="classes" class="base-button" v-bind="$attrs">
    <slot />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import BaseTooltip from '@/ui-library/base-tooltip/base-tooltip.vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    primary: {
      type: Boolean,
      required: false
    },
    bold: {
      type: Boolean,
      required: false
    },
    warning: {
      type: Boolean,
      required: false
    },
    caution: {
      type: Boolean,
      required: false
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  components: {
    BaseTooltip
  },
  setup(props) {
    const classes = computed(() => ({
      primary: props.primary,
      warning: props.warning,
      caution: props.caution,
      bold: props.bold
    }));
    return {
      classes
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';

.base-button {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid;
  cursor: pointer;
  padding: 0.6em 0.5em;
  font-size: 0.76rem;
  text-transform: capitalize;
  &:hover {
    transition: opacity 0.15s ease-in;
    box-shadow: 0px 0px 1px 0px $ava-border-color;
  }
  &:active {
    transform: scale(0.99);
    transition: opacity 0.15s ease-out;
  }
}
.primary {
  color: #14A992;
}
.bold {
  font-weight: bolder;
}
.warning {
  color: #FF7F50;
}
.caution {
  color: #931F1F;
}
</style>