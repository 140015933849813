export class EditorButtonService {

  constructor(editorContainerTarget) {
    this.editorContainerTarget = editorContainerTarget
    this.buttonNames = {
      bold: 'bold',
      italic: 'italic',
      bulletList: 'bulletList',
      orderedList: 'orderedList',
      h1: 'h1',
      h2: 'h2',
      'red-font': 'red-font',
      'blue-font': 'blue-font',
      'yellow-font': 'yellow-font',
      'green-font': 'green-font'
    }
    this.colorHexMap = { 
      red: '#D42210',
      blue: '#0072B2',
      yellow: '#E69F00',
      green: '#009E73'
    }
  }

  getButton(buttonName) {
    return this.editorContainerTarget.querySelector(`.ava-rich-textbox__button--${buttonName}`)
  }
  getButtonNames() {
    return Object.values(this.buttonNames)
  }

  highlightActiveButtons(editor) {
    this.getButtonNames().forEach((buttonName) => this.toggleButtonSelectedClass(buttonName, editor))
  }

  toggleButtonSelectedClass(buttonName, editor) {
    const button = this.getButton(buttonName)
    button?.classList?.toggle("ava-rich-textbox__button--selected", this.isButtonActive(buttonName, editor))
  }

  isButtonActive(buttonName, editor) {
    const button = this.getButton(buttonName)
    
    const isActiveMap = {
      h1: editor.isActive('heading', { level: 1 }),
      h2: editor.isActive('heading', { level: 2 }),
      'red-font': editor.isActive('textStyle', { color: this.colorHexMap.red }),
      'blue-font': editor.isActive('textStyle', { color: this.colorHexMap.blue }),
      'yellow-font': editor.isActive('textStyle', { color: this.colorHexMap.yellow }),
      'green-font': editor.isActive('textStyle', { color: this.colorHexMap.green })
    }
    return isActiveMap[buttonName] ?? editor.isActive(buttonName)
  }

  toggleBold(editor) {
    editor.chain().focus().toggleBold().run()
    this.toggleButtonSelectedClass('bold', editor)
  }

  toggleItalic(editor) {
    editor.chain().focus().toggleItalic().run()
    this.toggleButtonSelectedClass('italic', editor)
  }

  toggleH1(editor) {
    editor.chain().focus().toggleHeading({ level: 1 }).run()
    this.highlightActiveButtons(editor)
  }
  toggleH2(editor) {
    editor.chain().focus().toggleHeading({ level: 2 }).run()
    this.highlightActiveButtons(editor)
  }

  toggleBulletList(editor) { 
    editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList(editor) {
    editor.chain().focus().toggleOrderedList().run();
  }

  toggleFontColor(editor, color) {

    if (color) {
      editor.chain().focus().setColor(this.colorHexMap[color]).run()
      this.toggleButtonSelectedClass(`${color}-font`, editor)
    } else {
      editor.chain().focus().unsetColor().run()
    }
    this.highlightActiveButtons(editor)
  }

}