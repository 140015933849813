// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component-name (ie. shared-ui--checkmark-icon) in the component class.

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["button", "popup"];
  static classes = ["hiddenPopup", "activeButton"]
  static values = {
    triggerEventOnOpen: String,
    triggerEventOnClose: String,
    closeOnEvent: String,
    openOnEvent: String,
    useMutationObserver: Boolean
  }

  connect() {
    this.addOpenHooks();
    this.addCloseHooks();
    if (this.useMutationObserverValue) {
      this.addMutationObserver();
    }
  }

  addOpenHooks() {
    if (this.openOnEventValue) {
      document.addEventListener(this.openOnEventValue, this.showPopup());
    }
  }

  addCloseHooks() {
    if (this.closeOnEventValue) {
      document.addEventListener(this.closeOnEventValue, this.closePopup());
    }
  }

  showPopup() {
    this.popupTarget.classList.remove(this.hiddenPopupClass);
    this.buttonTarget.firstElementChild.classList.add(this.activeButtonClass);

    if (this.triggerEventOnOpenValue) {
      document.dispatchEvent(new CustomEvent(this.openEventNameValue));
    }
  }

  hidePopup() {
    this.popupTarget.classList.add(this.hiddenPopupClass);
    this.buttonTarget.firstElementChild.classList.remove(this.activeButtonClass);

    if (this.triggerEventOnCloseValue) { 
      document.dispatchEvent(new CustomEvent(this.closeEventNameValue));
    }
  }

  togglePopup(event) {
    if (this.popupTarget.classList.contains(this.hiddenPopupClass)) {
      this.showPopup();
    }
    else {
      this.hidePopup();
    }
  }

  addMutationObserver() {
    const contentArea = this.element.querySelector('.slide-in-popup');
  
    // Create a MutationObserver to monitor changes in the slide-in-popup
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.showPopup();
        }
      }
    });
  
    // Configure the observer to watch for child list changes
    const config = { childList: true, subtree: true };
  
    // Start observing the turbo frame for changes
    observer.observe(contentArea, config);
  }
}
