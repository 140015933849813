import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        // reloading page updates csrf token and it prevents need for double login
        window.location.href = '/users/sign_in';
      }
    });
  }
}
