import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["container", "button"];
  
  connect() {
    this.isInitialClick = true;
    this.isOpen = false;
    window.addEventListener("transcription:recordingStarted", this.handleRecordingStarted.bind(this));
    window.addEventListener("transcription:recordingStopped", this.handleRecordingStopped.bind(this));
  }

  disconnect() {
    window.removeEventListener("transcription:recordingStarted", this.handleRecordingStarted.bind(this));
    window.removeEventListener("transcription:recordingStopped", this.handleRecordingStopped.bind(this));
  }

  handleRecordingStarted() {
    console.log("Recording started");
    this.buttonTarget.classList.add("flashing");
    this.buttonTarget.classList.add("flash-primary");
  }

  handleRecordingStopped() {
    console.log("Recording stopped");
    this.buttonTarget.classList.remove("flashing");
    this.buttonTarget.classList.remove("flash-primary");
  }

  async handleClick(event) {
    event.preventDefault();
    
    if (this.isInitialClick) {
      await this.postTranscription();
      this.isInitialClick = false;
      this.showContainer();
      this.isOpen = true;
      return;
    }

    this.toggleContainer();
  }

  async handleRightClick(event) {
    event.preventDefault();
    
    if (this.isInitialClick) {
      this.isInitialClick = false;
    }

    await this.getPreviousTranscriptions();
    this.showContainer();
  }

  async postTranscription() {
    const patientId = this.buttonTarget.dataset.patientId;
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    
    try {
      const response = await fetch('/transcriber/transcriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({
          patient_id: patientId
        })
      });

      if (response.ok) {
        const html = await response.text();
        Turbo.renderStreamMessage(html);
      } else {
        console.error("Failed to create transcription");
      }
    } catch (error) {
      console.error("Error creating transcription:", error);
    }
  }

  async getPreviousTranscriptions() {
    const patientId = this.buttonTarget.dataset.patientId;

    try {
      const response = await fetch(`/transcriber/transcriptions?patient_id=${patientId}`, {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      });

      if (response.ok) {
        const html = await response.text();
        Turbo.renderStreamMessage(html);
      } else {
        console.error("Failed to fetch previous transcriptions");
      }
    } catch (error) {
      console.error("Error fetching previous transcriptions:", error);
    }
  }

  toggleContainer() {
    if (this.isOpen) {
      this.hideContainer();
    } else {
      this.showContainer();
    }
  }

  showContainer() {
    this.containerTarget.classList.remove('hidden-field');
    this.buttonTarget.classList.add('footer-button-active');
    this.isOpen = true;
  }

  hideContainer() {
    this.containerTarget.classList.add('hidden-field');
    this.buttonTarget.classList.remove('footer-button-active');
    this.isOpen = false;
  }

}
