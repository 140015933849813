// Add a Stimulus controller for this component.
// It will automatically registered and its name will be available
// via #component-name (ie. shared-ui--checkmark-icon) in the component class.
//
import { Controller as BaseController } from "@hotwired/stimulus";
import RequestController from "../../utilities/request_controller";

export class Controller extends BaseController {

  connect() {
    const requester = new RequestController;
    // TODO: get from html... maybe can modify utilities request?
    // can connect function have access to params?
    requester.fetchTurboStream({ params: { url: '/task_view/tasks/task_menu_index' } })
    Tooltipster.init();
  }

  disconnect() {
  }
}
