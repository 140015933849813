<template>
  <div class="base-tooltip-content">
    <div :id="contentId">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    contentId: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.base-tooltip-content {
  display: none;
}
</style>