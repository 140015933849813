<template>
  <div class="preview-patient-form-template">
    <base-button primary @click="openPreviewWindow" :disabled="!store.getPreviewUrlForTemplate"> Preview Form </base-button>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import BaseButton from '@/ui-library/base-button/base-button.vue';
import { PatientFormTemplateDetails } from '../../../../types/patient-form-template-details';
import { usePatientFormTemplateStore } from '../../../../store/custom-patient-forms-store';

const store = usePatientFormTemplateStore();

defineProps({
  formTemplate: {
    type: Object as PropType<PatientFormTemplateDetails | null>,
    required: true
  }
})

const openPreviewWindow = () => {
  window.open(store.getPreviewUrlForTemplate || '', '_blank', 'noreferrer');
}

</script>

<style lang="scss" scoped></style>