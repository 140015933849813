// Utilities for usage in channels
// Can split if getting too large

export function startLongPoller(longPoller, refetch, interval = 60000) {
  if (!longPoller) {
    longPoller = setInterval(() => {
      console.log('Refetching data due to long poller');
      refetch();
    }, interval);
  }
  return longPoller;
}

export function stopLongPoller(longPoller) {
  if (longPoller) {
    clearInterval(longPoller);
    longPoller = null;
  }
  return longPoller
}

export function markDivAsConnected(indicatorId, widgetId, longPoller) {
  const indicatorEl = document.getElementById(indicatorId);
  if (indicatorEl) {
    indicatorEl.classList.add('hidden-field');
  } else {
    console.log("No indicator div found at id: ", indicatorId);
  }
  const widgetEl = document.getElementById(widgetId);
  if (widgetEl) {
    widgetEl.setAttribute('data-channel-connected', true);
  }
  if (longPoller) {
    longPoller = stopLongPoller(longPoller);
  }
  return longPoller
}

export function markDivAsDisconnected(indicatorId, widgetId) {
  const indicatorEl = document.getElementById(indicatorId);
  if (indicatorEl) {
    indicatorEl.classList.remove('hidden-field');
  }
  
  const widgetElement = document.getElementById(widgetId);
  if (widgetElement) {
    widgetElement.removeAttribute('data-channel-connected');
  }
}
