import throttle from "lodash/throttle";
/**
 * This file is adapted from the original coffeescript implementation to vanilla JS
 */

class SessionPoller {

  constructor() { 
    // throttle every 2 minutes if session is still active
   this.throttledWindowFocusHandler = throttle(() => this.windowFocusHandler(), 120000); 
  }

  poll() {
    // check every 2 minutes if session is still active
    setTimeout(this.request.bind(this), 120000);
  }

  request() {
    /**
     * request endpoint hits /account_management/users/:user_id/session/active
     * see the app/controllers/account_management/session_controller.rb
     * app/views/account_management/session/active.js.erb file for the response: shows session countdown modal
     * The endpoint checks if time left in session is < 150 seconds then show modal for session is ending soon
     */
    const url = this._checkSessionActivity()
    if (url) {
      $.ajax(url, {
        method: 'GET',
        dataType: 'script'
      }).then(() => {
        this.poll();
      }).catch(error => {
        this.authErrorHandler(error);
      });
    }
  }

  authErrorHandler(error) {
    if (error?.status === 401) {
      window.location.reload();
    } else {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }

  _checkSessionActivity() {
    // returns "/account_management/users/:user_id/session/active" | null
    const urlElement = document.getElementById('session-notification');
    return urlElement ? urlElement.getAttribute('data-url') : null;
  }

  windowFocusHandler() {
    // hits server to see if still active, else reload page to send back to login
    const loggedInElement = document.getElementById('logged-in');
    const requestLocationElement = document.getElementById('request-location');
    const requestLocation = requestLocationElement?.getAttribute('data-request-location');
    if (loggedInElement) {
      var userId = loggedInElement.getAttribute('data-user-id');
      $.ajax(`/account_management/users/${userId}/session/window_focus?request_location=${requestLocation}`, {
        method: 'GET', // Assuming GET, update accordingly if needed
        dataType: 'script'
      }).catch(error => {
        this.authErrorHandler(error);
      });
    }
  }

}


// DOMContentLoaded event to ensure the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
  window.sessionPoller = new SessionPoller();

  if (document.getElementById('logged-in')) {
    window.sessionPoller?.poll();
  }

  window.addEventListener('focus', () => {
    // hits server to see if still active, else reload page to send back to login. see: windowFocusHandler
    window.sessionPoller?.throttledWindowFocusHandler();
  });

  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      // hits server to see if still active, else reload page to send back to login. see: windowFocusHandler
      window.sessionPoller?.throttledWindowFocusHandler();
    }
  });

});
