import { Controller } from "@hotwired/stimulus";
import zxcvbn from "zxcvbn";

export default class extends Controller {
  static targets = ["password", "strengthBar", "strengthLabel"]

  connect() {}
  
  updateStrengthBarStatus() {
    const password = this.passwordTarget.value;
    const strengthScore = this.#calculateStrength(password)

    if (password.length === 0) {
      return this.#resetLabelAndClass()
    }
    this.#updateLabel(strengthScore)
    this.#updateStrengthClass(strengthScore)
  }

  #calculateStrength(password) {
    return zxcvbn(password).score
  }
  
  #resetLabelAndClass() {
    this.#updateElementClass(this.strengthBarTarget, 'hidden')
    this.#updateElementClass(this.strengthLabelTarget, 'hidden')
  }

  #updateLabel(score) {
    const strengthLabelList = () => ([
      "Too short!",
      'Weak',
      'Mediocre!',
      'Good',
      'Strong'
    ])
    this.strengthLabelTarget.innerHTML = strengthLabelList()[score]
  }

  #updateStrengthClass(score) {
    const strengthLevelClassList = () => ([
      'level-0',
      'level-1',
      'level-2', 
      'level-3', 
      'level-4'
    ]);

    const strengthLevelClass = strengthLevelClassList()[score]

    this.#updateElementClass(this.strengthBarTarget, strengthLevelClass )
    this.#updateElementClass(this.strengthLabelTarget, strengthLevelClass)
  }

  #updateElementClass(targetElement, newClass) {
    const targetClassList = targetElement.classList
    const oldClass = targetClassList[1]
    
    targetClassList.replace(oldClass, newClass);
  }
  
  
}
