import { setConjunctionCharacter, setConjunctionWord, setRemoteUrl } from "./macro-text-helpers";
import { getOptionsFromDynamicMacro, isSelectionTextValid } from "./init-plain-text-dynamic-macros";

export class MacroMultiSelectService {
  constructor() {
    this.isOpen = false;
    this.isLastMacroInTextarea = false;
  }

  removeBodyClickListener() {
    $('body').off('click', removeMacroMultiSelect);
  }

  setBodyClickListener() {
    $('body').on('click', removeMacroMultiSelect);
  }

  appendMenu(div, top, left) {
    this.removeMenu();
    this.removeBodyClickListener()
    if (appendMacroMultiSelect(div, top, left)) {
      focusFirstMacroMultiSelect()
      this.setBodyClickListener()
      this.isOpen = true;
    } 
  }

  removeMenu() {
    this.isOpen = false;
    removeMacroMultiSelect();
  }

  launchMacroWindow(editor, dynamicMacroText) {
    if (!editor || !editor.state.selection) return null;
    const conjunctionCharacter = setConjunctionCharacter(dynamicMacroText);
    const conjunctionWord = setConjunctionWord(conjunctionCharacter);
    if (!isSelectionTextValid(dynamicMacroText, conjunctionCharacter)) return null;
    const remoteUrl = setRemoteUrl(dynamicMacroText, conjunctionCharacter);
    const options = getOptionsFromDynamicMacro(dynamicMacroText, conjunctionCharacter);
    const optionsWindowDiv = buildMultiSelect(options, conjunctionWord, remoteUrl);
    const { from } = editor.state.selection;
    const coords = editor.view.coordsAtPos(from);
    this.appendMenu(optionsWindowDiv, coords.top + 16, coords.left);

    if (options?.length === 1) {
      selectFocussedMacro();
    }
  }
}

export const removeMacroMultiSelect = () => {
	$('#macro-multi-select').remove();
	$('#macro-multi-select-info').remove();
}

export const focusFirstMacroMultiSelect = () => {
  $('#macro-multi-select').children().removeClass('macro-focussed')
	$('#macro-multi-select').find('p.macro-object').first().addClass('macro-focussed')
}

export const appendMacroMultiSelect = (el, top, left) => {
	$('#layout-content').append(el)
  const $macroMultiSelect = $('#macro-multi-select');
  if ($macroMultiSelect.length) {
    $macroMultiSelect.css({ 'top': top, 'left': left });
    return true
  }
}

export const openMultiSelectWindow = (options, conjunction, remoteUrl, top, left) => {
  const optionsWindowDiv = buildMultiSelect(options, conjunction, remoteUrl)
  appendMacroMultiSelect(optionsWindowDiv, top, left)
  focusFirstMacroMultiSelect()
}

export const buildMultiSelect = (options, conjunction, remoteUrl = null) => {
	let optionsData = ''
	if (remoteUrl) {
		getOptionsFromRemoteUrl(remoteUrl, options)
		optionsData = buildLoader()
	} else {
		optionsData = buildOptions(options)
	}
	return `<div id="macro-multi-select" data-conjunction="${conjunction}">
			<p class="info-text">
        ${options?.length === 1 ? 'Backspace - delete text <br>' : 'Space - toggle text <br>'}
				Enter - save checked text<br>
			</p>
			${optionsData}
		</div>`
}

export const moveMultiSelectFocus = (step) => { // step is either -1 or 1
	const currentIndex = $('#macro-multi-select').find('.macro-object').index($('.macro-focussed'))
	const maxIndex = $('#macro-multi-select').find('.macro-object').length - 1
	let newIndex = currentIndex + step
	// Do not allow to arrow off the menu
	if (newIndex < 0) {
		newIndex = 0
	} else if (newIndex > maxIndex) {
		newIndex = maxIndex
	}
	$('#macro-multi-select').children().removeClass('macro-focussed')
	$('#macro-multi-select').find('.macro-object').eq(newIndex).addClass('macro-focussed')
}

export const focusConfirmBox = (shouldFocus) => {
  const $macroMultiSelect = $('#macro-multi-select');
  const $macroConffirmButton = $('#macro-confirm-button');
  if (shouldFocus && $macroMultiSelect.length && $macroConffirmButton.length) {
    $('#macro-multi-select').children().removeClass('macro-focussed')
    $('#macro-confirm-button').addClass('macro-focussed')
  } else {
    $('#macro-confirm-button').removeClass('macro-focussed')
    focusFirstMacroMultiSelect()
  }
}

export const isConfirmBoxFocused = () => {
  const $macroConffirmButton = $('#macro-confirm-button');
  if ($macroConffirmButton.length) {
    return $macroConffirmButton.hasClass('macro-focussed')
  }
  return false;
}

export const selectFocussedMacro = () => {
	const focussedEl = $('#macro-multi-select').find('.macro-focussed')
	const icon = focussedEl.find('.macro-icon')
	if (focussedEl.hasClass('macro-unselected')) {
		focussedEl.removeClass('macro-unselected')
		focussedEl.addClass('macro-selected')
		icon.removeClass('fa-times')
		icon.addClass('fa-check')
	} else if (focussedEl.hasClass('macro-selected')) {
		focussedEl.addClass('macro-unselected')
		focussedEl.removeClass('macro-selected')
		icon.addClass('fa-times')
		icon.removeClass('fa-check')
	}
}

export const getNewPhrase = () => {
	let array = []
	const selected = $('#macro-multi-select').find('.macro-selected')
	const conjunction = $('#macro-multi-select').data('conjunction')
	selected.each(function(index) {
		array.push($(this).text().trim())
	});

	let length = array.length
	let outputString = ''
	array.forEach(function(text, index) {
		outputString += text
		if (length === 2) { outputString += ` ${conjunction} ` }
		if (length > 2) { outputString += ', ' }
		length -= 1
	});
	return outputString
}

export const getCursorXY = (input, selectionPoint) => {
	const inputX = $(input).offset().left
	const inputY = $(input).offset().top
  // create a dummy element that will be a clone of our input
  const div = document.createElement('div')
  // get the computed style of the input and clone it onto the dummy element
  const copyStyle = getComputedStyle(input)
  for (const prop of copyStyle) {
    div.style[prop] = copyStyle[prop]
  }
  // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
  const swap = '.'
  const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
  // set the div content to that of the textarea up until selection
  const textContent = inputValue.substr(0, selectionPoint)
  // set the text content of the dummy element div
  div.textContent = textContent
  if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
  // if a single line input then the div needs to be single line and not break out like a text area
  if (input.tagName === 'INPUT') div.style.width = 'auto'
  // create a marker element to obtain caret position
  const span = document.createElement('span')
  // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
  span.textContent = inputValue.substr(selectionPoint) || '.'
  // append the span marker to the div
  div.appendChild(span)
  // append the dummy element to the body
  document.body.appendChild(div)
  // get the marker position, this is the caret position top and left relative to the input
  const { offsetLeft: spanX, offsetTop: spanY } = span
  // lastly, remove that dummy element
  // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
  document.body.removeChild(div)
  // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
  return {
    x: inputX + spanX,
    y: inputY,
  }
}


// PRIVATE:

const getOptionsFromRemoteUrl = (remoteUrl, data) => {
	const patientId = $('#patient-chart').data('patient-id');
	$.ajax({
		url: remoteUrl,
		data: {
			search: data[0],
			patient_id: patientId
		},
		dataType: 'json',
		success: (data) => {
			$('#macro-multi-select').find('.loader').remove();
			$('#macro-multi-select').append(buildOptions(data.values));
			focusFirstMacroMultiSelect();
		}
	})
}

const buildOptions = (options) => {
	let optionsString = ''
	options.forEach(function (option, index) {
    if (option) {
      optionsString += `<p class="macro-unselected macro-object">
      <i class="fa fa-times macro-icon"></i>&nbsp;
      ${option.trim()}
      </p>`
    }
	});
  if (optionsString === '') {
    optionsString = '<p class="macro-object">No results found</p>'
  }
  const confirmBox = buildConfirmBox()
	return optionsString + confirmBox
}

const buildConfirmBox = () => {
	return `<div id="macro-confirm-button">
      <p class="submit-info">Enter to confirm &nbsp;<i class="fa fa-angle-double-right macro-icon"></i></p>
      <p class="cancel-info"><i class="fa fa-angle-double-left macro-icon"></i>&nbsp; Left Arrow to cancel</p>
		</div>`
}

const buildLoader = () => {
	return `<span class="loader">
		<i class="fa fa-spinner fa-spin"></i>
	</span>`
}