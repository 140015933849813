import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  submitForm(event) {
    const iconHTML = '<i style="position: relative;" class="fa fa-circle-o-notch fa-spin"></i>';
    this.formTarget.parentNode.insertAdjacentHTML('beforeend', iconHTML);
    this.formTarget.submit();
  }
}