import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    divIdToHideDuringSearch: String,
    patientIdClass: String,
    patientNameClass: String,
    patientSexClass: String,
    patientIdentifierClass: String,
    patientCityClass: String,
    patientProvinceClass: String,
  }

  initialize() {
    this.handlePatientSelected = this.handlePatientSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  connect() {
    this.element.addEventListener("patient:selected", this.handlePatientSelected);
    this.element.addEventListener('submit', this.handleSubmit);

    const autofocusField = this.element.querySelector('.focus-on-load');
    if (autofocusField) {
      autofocusField.focus();
    }
  }
  
  disconnect() {
    this.element.removeEventListener("patient:selected", this.handlePatientSelected);
    this.element.removeEventListener('submit', this.handleSubmit);
  }

  handlePatientSelected(event) {
    this.showDivOnSelect();
    this.hidePatientIndex();
    if (!this.element.contains(event.target)) return;
    const { patientId, patientName, patientIdentifier, patientSex, patientCity, patientProvince } = event.detail
    this.setSearchFieldText(patientName);
    this.updatePatientId(patientId);
    updateFieldsWithClass(this.patientNameClassValue, patientName);
    updateFieldsWithClass(this.patientIdentifierClassValue, patientIdentifier);
    updateFieldsWithClass(this.patientSexClassValue, patientSex);
    updateFieldsWithClass(this.patientCityClassValue, patientCity);
    updateFieldsWithClass(this.patientProvinceClassValue, patientProvince);
  }

  handleSubmit(event) {
    // Check if the event target is the form or a form submit button
    if (event.target.matches('form') || event.target.matches('.submit-form')) {
      event.preventDefault(); // Prevent the default form submission
      this.submitForm(event);
      this.hideDivDuringSearch();
      this.showPatientIndex();
    }
  }

  submitForm(event) {
    this.hideDivDuringSearch();
    event.preventDefault();
    const form = event.target;
    const url = new URL(form.action);
    const formData = new FormData(form);
    const searchParams = new URLSearchParams();
  
    for (const pair of formData) {
      searchParams.append(pair[0], pair[1]);
    }
  
    url.search = searchParams.toString();
  
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    })
    .then(response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
  
  updatePatientId(value) {
    console.log('PATIENT ID CLASS', this.patientIdClassValue);
    const htmlClass = cleanClassName(this.patientIdClassValue);
    updateFields(htmlClass, value);
  }

  hideDivDuringSearch(event) {
    if (!this.divIdToHideDuringSearchValue) return;
    const el = document.getElementById(this.divIdToHideDuringSearchValue);
    if (el) {
      el.classList.add('hidden-field');
    }
  }

  showDivOnSelect() {
    if (!this.divIdToHideDuringSearchValue) return;
    const el = document.getElementById(this.divIdToHideDuringSearchValue);
    if (el) {
      el.classList.remove('hidden-field');
    }
  }

  showPatientIndex() {
    const el = this.element.querySelectorAll('.patient-index-list')
    el.forEach((element) => {
      element.classList.remove('hidden-field');
    })
  }

  hidePatientIndex() {
    const el = this.element.querySelectorAll('.patient-index-list')
    el.forEach((element) => {
      element.classList.add('hidden-field');
    })
  }

  showPatientIndex() {
    const el = this.element.querySelectorAll('.patient-index-list')
    el.forEach((element) => {
      element.classList.remove('hidden-field');
    })
  }

  setSearchFieldText(patientName) {
    const el = document.getElementById('patient-magic-search-field')
    if (el) {
      el.value = patientName;
    }
  }

}

function cleanClassName(className) {
  if (className.startsWith('.')) {
    return className.slice(1);
  }
  return className;
}

function updateFields(htmlClass, value) {
  const fields = Array.from(document.getElementsByClassName(htmlClass));
  fields.forEach((field) => {
    field.value = value;
  })
}

function updateFieldsWithClass(htmlClass, value) {
  if (!htmlClass) return;
  const cleanedHtmlClass = cleanClassName(htmlClass);
  updateFields(cleanedHtmlClass, value);
}
