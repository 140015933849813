import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    aiPredictionUrl: String,
    prediction: String,
  }

  connect() {
    this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnter.bind(this));
  }

  handleMouseEnter() {
    this.selectExtractedElement();
  }

  clickExtractedElement() {
    this.selectExtractedElement();
    this.fetchExtractedElementForm();
  }

  selectExtractedElement() {
    const detail = JSON.parse(this.predictionValue);
    const event = new CustomEvent("decipher:select", { detail, bubbles: true });
    window.dispatchEvent(event);
  }

  fetchExtractedElementForm() {
    if (this.aiPredictionUrlValue) {
      fetch(this.aiPredictionUrlValue, {
        method: 'GET',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
    }
  }

}
