import { consumer, isSubscribed } from "../consumer"

// This channel allows the employee to receive new instant message threads
// If a colleague sends a new message to the current_employee they will have
// a new thread pin show up in the toolbar
// If the pin is already in the toolbar it will flash "unread message"

$(document).ready(function() {
  if ($('#footer-messenger').length > 0) {
    let employeeId = $('#messenger-index-button').data('employee-id');
    let subscriptionObject = {
      channel: 'EmrChannels::EmployeeInstantMessengerChannel',
      employee_id: employeeId
    }
    if (isSubscribed(subscriptionObject)) {
      return true;
    }
    consumer.subscriptions.create(
      subscriptionObject,
      {
        initialized() {
          // console.log('initializing')
        },
  
        received(data) {
          const threadId = data['thread_id']
          const footerMessengerPin = $(`.footer-messenger-pin[data-thread-id="${threadId}"]`)
          if (footerMessengerPin.length < 1) {
            // If thread is not pinned in the messenger bar pin it and mark it as unread
            $('#footer-messenger').append(data['html']);
            addUnreadFlag(threadId);
          } else {
            if (!footerMessengerPin.find('.footer-thread-container').is(':visible')) {
              addUnreadFlag(threadId)
            }
          }
        },
  
        // Call when subscription is ready for use on the server
        connected() {

        },
  
        // Called when the WebSocket connection is closed
        // disconnected() {
        //   this.uninstall()
        // },
  
        // // Called when the subscription is rejected by the server
        // rejected() {
        //   this.uninstall()
        // },
  
        // update() {
        //   this.documentIsActive ? this.appear() : this.away()
        // },
  
        // appear() {
        //   // Calls 'ClinicSessionStatusChannel#appear(data)' on the server
        //   this.perform("appear", { appearing_on: this.appearingOn })
        // },
  
        // away() {
        //   this.perform("away")
        // },
  
        // install() {
        //   window.addEventListener("focus", this.update)
        //   window.addEventListener("blur", this.update)
        //   window.addEventListener("turbolinks:load", this.update)
        //   window.addEventListener("visibilitychange", this.update)
        // },
  
        // uninstall() {
        //   window.removeEventListener("focus", this.update)
        //   window.removeEventListener("blur", this.update)
        //   document.removeEventListener("turbolinks:load", this.update)
        //   document.removeEventListener("visibilitychange", this.update)
        // },
  
        // get documentIsActive() {
        //   return document.visibilityState === "visible" && document.hasFocus()
        // },
  
        // get appearingOn() {
        //   const element = document.querySelector("[data-appearing-on]")
        //   return element ? element.getAttribute("data-appearing-on") : null
        // }
      }
    );
  }  
});

function addUnreadFlag(threadId) {
  $(`.footer-messenger-pin[data-thread-id="${threadId}"]`)
    .find('.footer-messenger-button')
    .addClass('instant-messenger-unread');
}
