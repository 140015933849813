import { defineStore } from 'pinia';
import { PatientChartState } from '../types/patient-chart-state.interface';

export const usePatientChartStore = defineStore('patientChartStore', {
  state: (): PatientChartState => ({
    patientId: null,
    showVideoLink: false
  }),
  getters: {
    getPatientId: (state) => state.patientId,
    getShowVideoLink: (state) => state.showVideoLink
  },
  actions: {
    setPatientId(id: number) {
      this.patientId = id;
    },
    toggleVideoLink(status?: boolean) {
      this.showVideoLink = status ?? !this.showVideoLink;
    }
  }
});