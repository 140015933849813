import { defineStore } from 'pinia';
import { withLoader } from '@/utils/store-helpers';
import { 
  createFormTemplate, 
  fetchMyAuthoredFormTemplates, 
  fetchFormTemplateDetails, 
  updateFormTemplate
} from '../services/custom-patient-forms-service';
import { PatientFormTemplateDetails, FormTemplateFormItem, FormItemGroup } from '../types/patient-form-template-details';
import { PatientFormTemplateListItem } from '../types/patient-form-template-list-item';
import { PatientFormTemplateState } from '../types/patient-form-template-state';
import { cloneFormItemById, deleteFormItemFromTemplate, replaceSection } from '../types/patient-form-template-details.functions';
import orderBy from 'lodash/orderBy'
import { createFlattenedLiteFormItemsFromTemplate } from '../types/patient-form-lite-form-item.functions';
import { FlattenedLiteFormItem } from '../types/patient-form-lite-form-item';

export const usePatientFormTemplateStore = defineStore('patientFormTemplateStore', {
  state: (): PatientFormTemplateState => ({
    isUserAdmin: false,
    newTemplateName: '',
    isLoading: true,
    template: null,
    patientFormList: null,
    isFormTemplateViewOpen: false,
    pagination: {
      page: 0,
      pages: 0
    }
  }),
  getters: {
    getPatientFormList: (state): PatientFormTemplateListItem[] | null => orderBy(state.patientFormList, ['createdAt'], ['desc']),
    getFormTemplate: (state): PatientFormTemplateDetails | null => state.template,
    getIsFormTemplateViewOpen: (state): boolean => state.isFormTemplateViewOpen,
    getTemplateId: (state): number | null => state.template?.id || null,
    getPreviewUrlForTemplate: (state): string | null => state.template?.id ? state.template.preview_url : null,
    getIsLoading: (state): boolean => state.isLoading,
    getPage: (state): number => state.pagination.page,
    getPages: (state): number => state.pagination.pages,
    getIsPublished: (state): boolean => Boolean(state.template?.globally_available),
    getFlattenedLiteFormItems: (state): FlattenedLiteFormItem[] => 
      state.template ? createFlattenedLiteFormItemsFromTemplate(state.template) : []
  },
  actions: {
    async createFormTemplate() {
      withLoader.call(this, async () => {
        const template = await createFormTemplate(this.newTemplateName)
        this.resetNewTemplateName();
        this.router.push({ name: 'patient-form-template', params: { id: template.id } })
      })
    },
    async fetchMyAuthoredFormTemplates(page) {
      withLoader.call(this, async () => {
        const res = await fetchMyAuthoredFormTemplates(page);
        this.isUserAdmin = res.isUserAdmin;
        this.patientFormList = res.list;
        this.pagination.page = res.pagination.page;
        this.pagination.pages = res.pagination.pages;
      })
    },
    async goToEditTemplate(id: number) {
      if (id === this.getTemplateId) {
        this.openFormTemplateView(this.template!);
      } else {
        withLoader.call(this, async () => {
          this.openFormTemplateView(await fetchFormTemplateDetails(id));
        })
      }
    },
    async updateFormTemplateFormItem(formItemId: string, updates: { [key: string]: any }, options = { replaceTemplateAsync: true }) {
      if (this.template) {
        const updatedTemplate = await updateFormTemplate(
          replaceSection(formItemId, updates, this.template)
        )
        this.isUserAdmin = updatedTemplate.is_user_admin;
        if (options.replaceTemplateAsync) {
          this.template = updatedTemplate
        }

      }
    },
    async updateFormTemplate() {
      if (this.template) {
        await updateFormTemplate(this.template)
      }
    },
    updateFormTemplateSync(formItemId: string, updates: { [key: string]: any }) {
      if (this.template) {
        this.template = replaceSection(formItemId, updates, this.template);
      }
    },
    async addFormItemToGroup(groupFormItem: FormItemGroup, formItem: FormTemplateFormItem) {
      if (this.template) {
        const item = cloneFormItemById(groupFormItem.id, this.template)
        if (item?.category === 'group') {
          item.form_objects.push(formItem);
          this.updateFormTemplateFormItem(item.id, item);
        }   
      }
    },
    async deleteFormItem(formItemId: string) {
      if (this.template) {
        this.template = await updateFormTemplate(
          deleteFormItemFromTemplate(formItemId, this.template)
        )
      }
    },
    async deleteFormTemplate() {
      if (this.template) {
        this.template.deleted_at = new Date().toISOString();
        await updateFormTemplate(this.template)
        this.router.push({ name: 'patient-form-templates-new' })
      }
    },
    async saveNewOrderOfFormObjects(groupFormItemId: string, formObjects: FormTemplateFormItem[]) {
      if (this.template?.form?.form_objects) {
        this.template = replaceSection(groupFormItemId, { form_objects: formObjects }, this.template);
        await updateFormTemplate(this.template);
      }
    },
    openFormTemplateView(form: PatientFormTemplateDetails) {
      this.template = form;
    },
    resetNewTemplateName() {
      this.newTemplateName = ''
    },
    initCustomFormTemplate(id: number) {
      this.goToEditTemplate(id);
    }
  }
});