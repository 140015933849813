<template>
  <edit-form-template-layout 
    v-if="getFormTemplate" 
    :formTemplate="getFormTemplate" 
  >
    <template #edit-form-header>
      <base-checkbox 
        :label="`Add this Ava eForm to ${clinicFormTemplateStore.getClinicName}'s collection`" 
        v-model="isFormAddedToMyClinic"
      />
      <edit-form-title
        :showPublishButton="getFormTemplate.is_user_admin"
        :isPublished="getFormTemplate.globally_available"
        @deleteTemplate="deleteTemplate"
        @publishTemplate="publishTemplate">
        <form-editor-input label="Title" :showPencil="false" v-model="templateNameModel" />
      </edit-form-title>
      <form-editor-textarea label="Description of your form (optional)" v-model="templateDescriptionModel" />
    </template>
    <template #form-template-questions>
      <base-draggable
        class="form-template-sections-container"
        animation="500"
        handle=".form-template-section-handle"
        v-model="formSections" 
        item-key="order">
        <template #item="{element}">
          <edit-form-item-group 
            :group="element"
            :openByDefault="openSectionCardByDefault(element)" 
            @delete="(payload) => deleteFormItem(payload.id, payload.label)">
            <base-draggable-handle 
              v-if="formSections.length > 1" 
              class="form-template-section-handle" /> 
          </edit-form-item-group>
        </template>
      </base-draggable>
      <form-editor-card-header
        class="add-group-button"
        @click="addSection" tabIndex="0">
        <base-typography bold capitalize>Add a section</base-typography>
        <i class="fa fa-plus" /> 
      </form-editor-card-header>
      <form-editor-saving-message :flashText="flashText" :flashType="flashType" />
    </template>
  </edit-form-template-layout>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import EditFormItemGroup from './features/edit-form-item-group/edit-form-item-group.vue';
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseCheckbox from '@/ui-library/base-checkbox/base-checkbox.vue';
import BaseDraggableHandle from '@/ui-library/base-draggable/base-draggable-handle.vue';
import BaseDraggable from '@/ui-library/base-draggable/base-draggable.vue';
import { FormItemGroup, FormTemplateFormItem } from '../../../../types/patient-form-template-details';
import { createDefaultGroupFormItem } from '../../../../types/patient-form-template-details.functions';
import EditFormTemplateLayout from './layouts/edit-form-template-layout.vue';
import FormEditorCardHeader from './components/form-editor-card-header.vue';
import FormEditorInput from './features/edit-form-item-group/components/form-editor-input.vue';
import FormEditorTextarea from './features/edit-form-item-group/components/form-editor-textarea.vue';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import { useFlashMessage } from '@/composition/use-flash-message';
import FormEditorSavingMessage from './features/edit-form-item-group/components/form-editor-saving-message.vue';
import EditFormTitle from './components/edit-form-title.vue';
import { usePatientFormTemplateStore } from '../../../../store/custom-patient-forms-store';
import { useMyClinicFormTemplatesStore } from '../../../../store/my-clinic-form-templates-store';

const formTemplateStore = usePatientFormTemplateStore();
const clinicFormTemplateStore = useMyClinicFormTemplatesStore();

const {
  getFormTemplate
} = storeToRefs(formTemplateStore);

const addSection = () => {
  if (getFormTemplate.value) { 
    formTemplateStore.addFormItemToGroup(
      getFormTemplate.value.form, 
      createDefaultGroupFormItem({ 
        label: `section ${getFormTemplate.value.form?.form_objects?.length + 1}`,
        order: getFormTemplate.value.form.form_objects.length + 1
      })
    )
  }
}

const deleteFormItem = (formItemId: string, title: string) => {
  if (window.confirm(`Are you sure you want to delete ${title}?`)) {
    formTemplateStore.deleteFormItem(formItemId);
  }
}

const deleteTemplate = () => {
  if (window.confirm(`Are you sure you want to delete ${startCase(templateNameModel.value)}`)) {
    formTemplateStore.deleteFormTemplate();
  }
}

const publishTemplate = () => {
  if (!isPublished.value) {
    if (window.confirm("Are you sure you want to PUBLISH your form? Your form will be globally available to browse for all clinics that use Ava EMR! Publishing your form is not common.")) {
      toggleIsPublished()
    }
  } else {
    if (window.confirm("Are you sure you want to unpublish your form? Your form will no longer be globally available to browse for all clinics that use Ava EMR!")) {
      toggleIsPublished()
    }
  }
}

const openSectionCardByDefault = (section: FormItemGroup) => {
  return getFormTemplate.value
    ? getFormTemplate.value.form.form_objects.length < 2 || section.form_objects.length === 0
    : false 
}

const templateDescriptionModel =  computed({ 
  get: () => formTemplateStore?.getFormTemplate?.form.help || '', 
  set: (value) => formTemplateStore.template ? formTemplateStore.template.form.help = value : null
})

const templateNameModel =  computed({ 
  get: () => formTemplateStore?.getFormTemplate?.name || '', 
  set: (value) => formTemplateStore.template ? formTemplateStore.template.name = value : null
})

const isPublished =  computed({ 
  get: () => formTemplateStore?.getIsPublished, 
  set: (value) => formTemplateStore.template ? formTemplateStore.template.globally_available = value : null
})

const toggleIsPublished = () => {
  isPublished.value = !isPublished.value
}

const isFormAddedToMyClinic =  computed({ 
  get: () => clinicFormTemplateStore.isFormAddedToMyClinic(formTemplateStore.getTemplateId),
  set: (value: boolean) => clinicFormTemplateStore.updateClinicFormTemplate(value, formTemplateStore.getTemplateId)
})

const flashMessage = useFlashMessage();
const { flashText, flashType } = flashMessage;

const updateTemplateDebounced = debounce(async() => {
  try {
    await formTemplateStore.updateFormTemplate()
    flashType.value = 'primary';
    flashText.value = 'Your form is saved'
    // @ts-ignore
  } catch(e: Error) {
    flashText.value = `Warning your form did not save! ${e?.response.data?.base[0]}`;
    flashType.value = 'warning';
    flashMessage.clearTimer();
  }
}, 300);

watch([templateNameModel, isPublished, templateDescriptionModel], () => {
  updateTemplateDebounced()
})

const formSections = computed({
  get: (): FormTemplateFormItem[] => formTemplateStore.$state.template?.form?.form_objects || [],
  set: (value: FormTemplateFormItem[]) => {
    if (getFormTemplate.value) {
      formTemplateStore.saveNewOrderOfFormObjects(
        getFormTemplate.value.form.id,
        value.map((o, i) => { o.order = i + 1; return o; })
      )
    }
  }
})

const drag = false; // needed for draggable do not remove unless you test if dragging items still works

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.form-template-sections-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;    
}
.add-group-button {
  margin-top: 0.5em;
  border: 1px solid $ava-border-color;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
}
</style>