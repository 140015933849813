import $ from 'jquery'

window.addEventListener('DOMContentLoaded', (function(e) {

  $(document).on('click', '.toggle-focus-mode', function() {
    let container = $(this).closest('.focus-mode-container')
    if (container.hasClass('focus-mode-active')) {
      container.removeClass('focus-mode-active')
      $(this).removeClass('fa-compress').addClass('fa-expand')
    } else {
      container.addClass('focus-mode-active')
      $(this).removeClass('fa-expand').addClass('fa-compress')
    }
  });

}));
