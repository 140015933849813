<template>
  <div class="base-list-item-container" :class="classes">
    <slot />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
const props = defineProps({
  outlined: {
    type: Boolean,
    default: false
  },
  selectedOutline: {
    type: Boolean,
    default: false
  },
  clickable: {
    type: Boolean,
    default: true
  },
  showSeparationBorders: {
    type: Boolean,
    default: true
  }
})

const classes = computed(() => ({
  'base-list-item-outlined': props.outlined,
  'base-list-item-selected-outline': props.selectedOutline,
  'separation-border': props.showSeparationBorders,
  'base-list-item-clickable': props.clickable,
  faded: !props.clickable
}))

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.base-list-item-container {
  padding: 1em;
  background-color: inherit;
  border: none;
}
.base-list-item-container.separation-border {
  border-bottom: 1px solid $ava-border-color;
}
.base-list-item-container:last-child {
  border-bottom: none;
}
.base-list-item-container.base-list-item-outlined {
  border: 2px solid $ava-border-color;
  &:not(:first-child) {
    border-top: none;
  }
  &:not(:first-child).base-list-item-selected-outline {
    border: 2px solid $ava-affirmative-color;
  }
}
.base-list-item-container.base-list-item-selected-outline {
  border: 2px solid $ava-affirmative-color;
}
.faded {
  opacity: 0.5;
  &:hover {
    opacity: 0.7;
  }
}
.base-list-item-clickable {
  cursor: pointer;
  &:hover,
  &:focus {
    transition: opacity 0.15s ease-in;
    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  }
  &:active {
    transition: opacity 0.15s ease-out;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(136, 98, 98, 0.1));
  }
}
</style>
