<template>
  <form-editor-card 
    class="edit-group-container" 
    :title="group.label"
    :openByDefault="openByDefault">
    <template #header="headerProps">
      <form-editor-card-header 
        :title="group.label" 
        :isDetailsOpen="headerProps.isDetailsOpen"
        class="edit-group-card-header-row"
        :class="{ active: headerProps.isDetailsOpen }"
        @delete="$emit('delete', { id: group.id, label: group.label })">
        <template #pre-title>
          <slot />
        </template>
      </form-editor-card-header>
    </template>
    <template #body>
      <div class="edit-group-body">
        <form-editor-input label="section name" v-model="label" /> 
        <form-editor-textarea label="Description of your section (optional)" v-model="description" />
        <form-editor-conditional-section
          v-model="display" 
          :liteFormItems="store.getFlattenedLiteFormItems" 
        />
        <base-draggable
          class="edit-form-item-list-container"
          animation="500"
          handle=".form-template-section-item-handle"
          v-model="formItems" 
          item-key="order">
          <template #item="{element}">            
            <form-editor-card 
              :title="element.label"
              @delete="$emit('delete', { id: element.id, label: element.label })">
              <base-draggable-handle 
                v-if="formItems.length > 1" 
                class="form-template-section-item-handle" />
              <template #body="{ closeDetails }">
                <select-form-item 
                  v-if="element.category === 'select'" 
                  :item="element" 
                  :liteFormItems="store.getFlattenedLiteFormItems"
                  @save="replaceFormObject"
                  @close="closeDetails"
                />
                <date-form-item 
                  v-if="element.category === 'date'" 
                  :item="element" 
                  :liteFormItems="store.getFlattenedLiteFormItems"
                  @save="replaceFormObject"
                  @close="closeDetails"
                />
                <number-form-item 
                  v-if="element.category === 'number'" 
                  :item="element" 
                  :liteFormItems="store.getFlattenedLiteFormItems"
                  @save="replaceFormObject"
                  @close="closeDetails"
                />
                <text-form-item 
                  v-if="element.category === 'text'" 
                  :item="element" 
                  :liteFormItems="store.getFlattenedLiteFormItems"
                  @save="replaceFormObject"
                  @close="closeDetails"
                />
              </template>
            </form-editor-card>
          </template>
        </base-draggable>

        <new-form-item
          v-if="group.form_objects"
          :nextOrderValue="group.form_objects.length + 1" 
          :isAddQuestionFormOpen="isAddQuestionFormOpen"
          @submit="addFormObject"
          @close="toggleAddQuestionForm(false)"
          @toggleAddQuestionForm="toggleAddQuestionForm"
        />
      </div>
      <form-editor-saving-message :flashText="flashText" :flashType="flashType" />
    </template>
  </form-editor-card>
</template>

<script setup lang="ts">

import debounce from 'lodash/debounce'; 
import { computed, PropType, ref } from 'vue';
import FormEditorCard from './components/form-editor-card.vue';
import { usePatientFormTemplateStore } from '@/features/custom-form-templates/store/custom-patient-forms-store';
import { FormItemGroup, FormTemplateFormItem } from '@/features/custom-form-templates/types/patient-form-template-details';
import NewFormItem from './components/new-form-item.vue';
import FormEditorInput from './components/form-editor-input.vue';
import SelectFormItem from './components/form-item-components/select-form-item.vue';
import DateFormItem from './components/form-item-components/date-form-item.vue';
import NumberFormItem from './components/form-item-components/number-form-item.vue';
import TextFormItem from './components/form-item-components/text-form-item.vue';
import BaseDraggable from '@/ui-library/base-draggable/base-draggable.vue';
import BaseDraggableHandle from '@/ui-library/base-draggable/base-draggable-handle.vue';
import FormEditorCardHeader from '../../components/form-editor-card-header.vue';
import { useFlashMessage } from '@/composition/use-flash-message';
import FormEditorSavingMessage from './components/form-editor-saving-message.vue';
import FormEditorTextarea from './components/form-editor-textarea.vue';
import FormEditorConditionalSection from './components/conditional-components/form-editor-conditional-section.vue';

const store = usePatientFormTemplateStore();
defineEmits(['update:modelValue', 'delete'])

const props = defineProps({
  openByDefault: {
    type: Boolean,
    default: false
  },
  group: {
    type: Object as PropType<FormItemGroup>,
    required: true
  }
})

const flashMessage = useFlashMessage();
const { flashText, flashType } = flashMessage;

const saveFormItem = debounce(async (id: string, updates: Partial<FormItemGroup>, options = { replaceTemplateAsync: true }) => {
  if (options.replaceTemplateAsync === false) {
    store.updateFormTemplateSync(id, updates)
  }
  try {
    await store.updateFormTemplateFormItem(id, updates, options);
    flashType.value = 'primary';
    flashText.value = 'Your form is saved'
    // @ts-ignore
  } catch(e: Error) {
    flashText.value = `Warning your form did not save! ${e?.response.data?.base[0]}`;
    flashType.value = 'warning';
    flashMessage.clearTimer();
  }

}, 300, { trailing: true });

const addFormObject = (formObject: FormTemplateFormItem) => {
  saveFormItem(props.group.id, { form_objects: [...props.group.form_objects, formObject] })
}

const replaceFormObject = (formObject: FormTemplateFormItem) => {
  const newFormObjectList = props.group.form_objects.map(i => formObject.id == i.id ? formObject : i);
  saveFormItem(props.group.id, { form_objects: newFormObjectList })
}

const isAddQuestionFormOpen = ref(false);
const toggleAddQuestionForm = (status: boolean) => {
  isAddQuestionFormOpen.value = status ?? !isAddQuestionFormOpen.value
}

const label = computed({
  get: (): string => props.group.label,
  set: (value: string) => {
    saveFormItem(props.group.id, { label: value }, { replaceTemplateAsync: false })
  }
});
const description = computed({
  get: (): string => props.group.help,
  set: (value: string) => {
    saveFormItem(props.group.id, { help: value }, { replaceTemplateAsync: false })
  }
});

const formItems = computed({
  get: (): FormTemplateFormItem[] => props.group.form_objects,
  set: (value: FormTemplateFormItem[]) => {
      store.saveNewOrderOfFormObjects(
        props.group.id,
        value.map((o, i) => { o.order = i + 1; return o; })
      )
  }
})

const display = computed({
  get: () => props.group.display,
  set: (value) => {
    saveFormItem(props.group.id, { display: value })
  }
})

// Do not remove, needed for draggable
const drag = false;

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.edit-group-container {
    background-color: $ava-background-color;
  .edit-group-card-header-row {
    padding: 1em;
    background-color: white;
    display: flex;
    justify-content: space-between;
    &.active {
      background-color: $ava-background-color;
    }
  }
  .edit-group-body {
    background-color: $ava-background-color;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  .edit-form-item-list-container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;    
  }
}
</style>