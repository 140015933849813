import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "form"];
  
  connect() {
    
  }

  confirm(event) {
    event.preventDefault();
    if (this.inputTarget.value.toLowerCase() === "confirm") {
      // Submit the form
      Turbo.navigator.submitForm(this.formTarget);
    } else {
      // Alert the user
      alert("Please type 'confirm' to proceed.");
    }
  }
  
}