import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  closeForm() {
    const formDiv = document.getElementById('decipher-form');
    if (formDiv) {
      formDiv.innerHTML = '';
      formDiv.classList.add('hidden-field');
    }

    const indexDiv = document.getElementById('decipher-index');

    if (indexDiv) {
      indexDiv.classList.remove('hidden-field');
    }
  }

}
