import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-frame"
export default class extends Controller {

    connect() {
        // alert("close-frame is online!")
    }

    close({params: {id}}) {
        const frame = document.getElementById(id)
        frame.removeChild(frame.firstElementChild)
    }
}