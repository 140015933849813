import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkboxesDiv"];

  connect() {
    this.pmhxCheckbox = this.checkboxesDivTarget.querySelector("#past-medical-history-checkbox");
    this.includeInactivePmhxLabel = this.checkboxesDivTarget.querySelector("#include-inactive-pmhx-label");
    this.handlePmhxCheckboxChange();
  }
  
  handlePmhxCheckboxChange(event) {
    if (this.pmhxCheckbox?.checked) {
       this.includeInactivePmhxLabel?.classList.remove("hidden");
    }
    else {
      this.includeInactivePmhxLabel?.classList.add("hidden");
    }
  }

}