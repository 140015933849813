export const saveSelectedTextAsMacro = (opt) => {
  const user_id = $('.load-user-macros').data('user-id');
  const employee_id = $('.load-user-macros').data('employee-id');
  $.ajax({
    url: `/account_management/users/${user_id}/employees/${employee_id}/macros/new`,
    data: { macro: { content: selected } },
    dataType: 'script'
  });
}

export const generatePastMedicalHistoryText = (data) => {
  let outputString = "Past Medical History\n-------------------------------------\n";
  $.each(data, (index, pmhx) => {
    outputString += `${index + 1}. ${pmhx.history}`;
    if (pmhx.fuzzy_date_of_onset) {
      outputString += ` (Onset: ${pmhx.fuzzy_date_of_onset})`;
    }
    outputString += "\n";
    const lines = pmhx.notes?.split("\n") || [];
    $.each(lines, (index, line) => {
      if (line) {
        outputString += `  - ${line}\n`;
      }
    });
  });
  return outputString
};

export const generateSocialHistoryText = (data) => {
  let outputString = "Social History\n-------------------------------------\n";
  $.each(data, (index, sx) => {
    outputString += `${index + 1}.`;
    if (sx.category !== 'Other') {
      outputString += ` ${sx.category}`;
    }
    if (sx.name) {
      outputString += ` | ${sx.name}`;
    }
    if (sx.status) {
      outputString += ` | ${sx.status}`;
    }
    outputString += "\n";
    const lines = sx.notes?.split("\n") || [];
    $.each(lines, (index, line) => {
      if (line) {
        outputString += `  - ${line}\n`;
      }
    });
  });
  return outputString
};

export const generateFamilyHistoryText = (data) => {
    let outputString = "Family History\n-------------------------------------\n";
    $.each(data, (index, fx) => {
        outputString += `${index + 1}.`;
        if (fx.relationship) {
            outputString += ` ${fx.relationship}`;
        }
        if (fx.relative) {
            outputString += ` ${fx.relative}`;
        }
        if (fx.age) {
            outputString += ` (Age: ${fx.age})`;
        }
        outputString += " |";
        if (fx.history) {
            outputString += ` ${fx.history}`;
        }
        if (fx.name !== 'Other') {
            outputString += ` ${fx.name}`;
        }
        outputString += "\n";
        const lines = fx.notes?.split("\n") || [];
        $.each(lines, (index, line) => {
            if (line) {
                outputString += `  - ${line}\n`;
            }
        });
    });
    return outputString;
};

export const generateAllergyText = (data) => {
    let outputString = "Allergies\n-------------------------------------\n";
    $.each(data, (index, allergy) => {
        outputString += `${index + 1}. ${allergy.name}`;
        if (allergy.severity) {
            outputString += ` | ${allergy.severity}`;
        }
        if (allergy.category) {
            outputString += ` (${allergy.category})`;
        }
        outputString += "\n";
        const lines = allergy.notes?.split("\n") || [];
        $.each(lines, (index, line) => {
            if (line) {
                outputString += `  - ${line}\n`;
            }
        });
    });
    return outputString;
};

export const generateMedicationText = (data) => {
    let outputString = "Medications\n-------------------------------------\n";
    $.each(data, (index, med) => {
        outputString += `${index + 1}. ${med.drug_name}`;
        if (med.min_dosage) {
            outputString += ` ${med.min_dosage}`;
        }
        if (med.max_dosage) {
            outputString += `- ${med.max_dosage}`;
        }
        if (med.dosage_unit) {
            outputString += ` ${med.dosage_unit}`;
        }
        if (med.prn) {
            outputString += " PRN";
        }
        if (med.frequency) {
            outputString += ` ${med.frequency}`;
        }
        if (med.duration && !med.long_term) {
            outputString += ` x ${med.duration} ${med.duration_unit}`;
        }
        outputString += "\n";
    });
    return outputString;
};