import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clickEvent() {
    const parentForm = this.element.closest('.patient-search-ui-form');
    if (parentForm) {
      const includeDeceasedCheckbox = parentForm.querySelector(".include_deceased");
      const includeNotAuthorizedCheckbox = parentForm.querySelector(".include_not_authorized");

      if (includeDeceasedCheckbox) {
        includeDeceasedCheckbox.checked = true;
      }

      if (includeNotAuthorizedCheckbox) {
        includeNotAuthorizedCheckbox.checked = true;
      }

      const form = parentForm.querySelector('form');

      if (form) {
        const detail = { location: 'patient-select' }
        form.dispatchEvent(new Event("submit", { detail, bubbles: true }))
      }
    }
  }
}
