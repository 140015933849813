import { FlattenedLiteFormItem } from './patient-form-lite-form-item';
import { FormTemplateFormItem, PatientFormTemplateDetails } from './patient-form-template-details';

interface ProtoFlattenedLiteFormItem {
  id: string,
  breadcrumbs: string[]
}

export function createFlattenedLiteFormItemsFromTemplate(
  template: PatientFormTemplateDetails
): FlattenedLiteFormItem[] {
  return createFlattenedLiteFormItemsFromFormItemList(
    [],
    template.form.form_objects
  ).map(i => ({
    ...i,
    breadcrumbs: i.breadcrumbs.join(' - ')
  }))
}

function createFlattenedLiteFormItemsFromFormItemList(
  parentLabels: string[],
  list: FormTemplateFormItem[]
): ProtoFlattenedLiteFormItem[] {
  return list.reduce(
    (prev, currentValue) => {
      if (currentValue.category === 'group') {
        return [
          ...prev,
          ...createFlattenedLiteFormItemsFromFormItemList(
            [...parentLabels, currentValue.label], 
            currentValue.form_objects
          )
        ]
      }
      return [
        ...prev, 
        { 
          id: currentValue.id, 
          breadcrumbs: [...parentLabels, currentValue.label] 
        }
      ]
    },
    [] as ProtoFlattenedLiteFormItem[]
  );
}

