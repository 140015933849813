<template>
  <div class="base-drop-down-menu">
    <slot name="button" :toggleShowMenu="toggleShowMenu" />
    <base-overlay-background
      :showBackground="showMenu"
      @backgroundClicked="toggleShowMenu(false)"
    />
    <div class="drop-down-container">
      <div
        v-if="showMenu"
        class="menu"
        @click.stop="toggleShowMenu(false)"
        :style="menuStyles"
      >
        <slot name="list" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useToggledValue } from '@/composition/use-toggled-value';
import BaseOverlayBackground from '../base-overlay-background/base-overlay-background.vue';

const props = defineProps({
  align: {
    type: String,
    default: 'left',
    validator: (value: string) => ['left', 'right'].indexOf(value) !== -1
  }
})

const emit = defineEmits<{
  (e: 'menuClicked', value: boolean)
}>()

const { isTrue: showMenu, toggleValue: toggleShowMenu } = useToggledValue({ 
  customCallback: (value) => {
    emit('menuClicked', value);
  }
});

const menuStyles = computed(() => ({
  [props.align]: 0
}));

</script>

<style lang="scss" scoped>
@import '@/styles/global-styles.scss';
.base-drop-down-menu {
  height: 100%;
  .drop-down-button-list-background {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
  .drop-down-container {
    position: relative;
    height: 100%;
    .menu {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
      min-width: 100px;
      z-index: 2;
      position: absolute;
      background-color: $ava-background-color;
      @media (max-width: $ava-breakpoint-sm) {
        max-width: 100vw;
      }
      button {
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
</style>
