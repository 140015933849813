<template>
  <div class="base-pagination-buttons">
    <base-button-icon @click="$emit('prev', previousPage)"><i class="fa fa-chevron-left" /></base-button-icon>
    <base-typography>{{ page }} of {{ pages }}</base-typography>
    <base-button-icon @click="$emit('next', nextPage)"><i class="fa fa-chevron-right" /></base-button-icon>
  </div>
</template>

<script setup lang="ts">
import BaseTypography from '@/ui-library/base-typography/base-typography.vue';
import BaseButtonIcon from '@/ui-library/base-button/base-button-icon.vue';
import { computed } from 'vue';

defineEmits(['next', 'prev'])

const props = defineProps({
  page: {
    type: Number,
    required: true
  },
  pages: {
    type: Number,
    required: true
  }
})

const previousPage = computed(() => props.page > 1 ? props.page - 1 : props.page);
const nextPage = computed(() => props.page < props.pages ? props.page + 1 : props.page);

</script>

<style lang="scss" scoped>
.base-pagination-buttons {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
}
</style>